<template>
  <common-card class="profile-wrapper">
    <v-container>
      <v-dialog
        v-model="openEditBannerModal"
        max-width="900px"
        persistent
        attach="body"
      >
        <ModalEditarMarcaAlianza
          :idAliance="alianzaIdFromRoute"
          :nameAlliance="nombreAlianza"
          v-model="openEditBannerModal"
        />
      </v-dialog>
      <v-col cols="12">
        <h2>{{ nombreAlianza }}</h2>
      </v-col>
      <v-row>
        <v-col cols="12" style="position: relative;">
          <v-img
            :src="
              coverImage
                ? coverImage
                : 'https://via.placeholder.com/820x312.png?text='
            "
            class="cover-image"
          ></v-img>
        </v-col>
        <v-row class="align-items-end">
          <v-col cols="auto" style="margin-top: -32px; position: relative;">
            <v-avatar
              size="100"
              class="ml-6"
              :color="color ? color : '#00A5DF'"
            >
              <v-img
                :src="logo ? logo : 'https://via.placeholder.com/.png?text'"
                alt="Logo"
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col>
            <div class="id-container">
              <p>ID {{ this.idParamOrStorage() }}</p>
            </div>
          </v-col>
          <v-col
            cols="auto"
            class="edit-button-container"
            v-if="isAdminAgent || rol == 'AGENTCORP'"
          >
            <v-btn
              class="common-botton-alianza edit-profile-btn"
              dark
              @click="openEditBannerModal = true"
            >
              <span>Editar Perfil</span>
              <v-icon class="responsive-icon ml-2">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Asesores"
            :total="countAgente"
            :color="viewScreen === 1 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(1)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Total de prima neta"
            :total="countPrimaNeta"
            :color="viewScreen === 2 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="true"
            @view="changeView(2)"
            :arrow="false"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Cotizaciones"
            :total="countCotizaciones"
            :color="viewScreen === 3 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(3)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Solicitudes"
            :total="countSolicitudes"
            :color="viewScreen === 4 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(4)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Pólizas"
            :total="countEmisiones"
            :color="viewScreen === 5 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(5)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Clientes"
            :total="countCliente"
            :color="viewScreen === 6 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(6)"
          />
        </v-col>
      </v-row>

      <!-- Contenedor principal para el contenido dinámico -->
      <div class="dynamic-content-wrapper">
        <!-- Componente para agregar asesor, recibe ID de alianza y emite evento para recargar tabla -->
        <AgregarAsesor
          :idAliance="alianzaIdFromRoute"
          @reloadTable="loadTableData"
          @updateCountAgente="getCountAgente"
        />

        <br />

        <!-- Componente de detalle de búsqueda, visible cuando viewScreen es 1, emite eventos de actualización de filtro -->
        <busqueda-detalle
          @update:filter="updateFilter"
          v-if="viewScreen === 1"
        />
        <!-- Contenedor de fila para la tabla de alianzas, visible cuando viewScreen es 1 -->
        <v-row v-if="viewScreen === 1">
          <!-- Columna que ocupa todo el ancho -->
          <v-col cols="12">
            <!-- Componente de tabla de alianzas con estado de carga y datos, puede activar recarga de tabla -->
            <tabla-alianza
              :loading="loading"
              :items="alianza"
              @reloadTable="loadTableData"
              :statusMap="statusMap"
              :headers="headers"
            />
          </v-col>
        </v-row>

        <!-- Panel de datos de alianza, visible cuando viewScreen es 1 y el usuario es agente administrador -->
        <DataAlianzaPanel
          v-if="viewScreen === 1 && isAdminAgent"
          :alianzaData="alianzaData"
        />
        <!-- Componente de documentos de alianza, visible cuando viewScreen es 1 y el usuario es agente administrador -->
        <DocumentosAlianza
          v-if="viewScreen === 1 && isAdminAgent"
          :alianzaData="alianzaData"
          class="mb-16"
        />
        <!-- <buton
          v-if="viewScreen === 1 && isAdminAgent"
          style="display: flex; align-items: center; gap: 8px; background-color: #00a7e4; padding: 8px 16px; border-radius: 20px; color: white; cursor: pointer; width:fit-content"
          @click="
            () => $router.push(`/usuario/ver-micuenta/${this.agentCorpId}`)
          "
        >
          Ver/Editar perfil de Agente Corporativo
          <v-icon color="white">mdi-open-in-new</v-icon>
        </buton> -->

        <!-- <SecurityDataPanel
          v-if="viewScreen === 1 && isAdminAgent"
          :routesConfigPassword="routesConfigPassword"
        /> -->

        <div
          style="box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);margin: 16px 0;display: flex; flex-direction: row; padding: 16px 30px; background-color: aliceblue; border-radius: 20px; align-items: center; justify-content: space-between; flex-wrap: wrap; gap:24px"
          v-if="viewScreen === 1 && isAdminAgent"
        >
          <div
            style="display: flex;
        flex-direction: column; gap:8px;"
          >
            <p style="font-size: 18px; margin: 0">
              <strong>
                Asesor Corporativo a cargo:
              </strong>
            </p>

            <p style="font-size: 16px; margin: 0">
              {{ alianzaData.nombre }}&nbsp;{{
                alianzaData.apellidoPaterno
              }}&nbsp;{{ alianzaData.apellidoMaterno }}
            </p>
          </div>
          <buton
            style="display: flex; align-items: center; gap: 8px; background-color: #00a7e4; padding: 8px 16px; border-radius: 20px; color: white; cursor: pointer;"
            @click="
              () => $router.push(`/usuario/ver-micuenta/${this.agentCorpId}`)
            "
          >
            Ver/Editar perfil de Agente
            <v-icon color="white">mdi-open-in-new</v-icon>
          </buton>
        </div>
        <!-- Columna para sección de Cotizaciones -->
        <v-col>
          <!-- Componente índice de cotizaciones, visible cuando viewScreen es 3 -->
          <CotizacionesIndex v-if="viewScreen === 3" />
        </v-col>
        <!-- Columna para sección de Solicitudes -->
        <v-col>
          <!-- Componente índice de solicitudes, visible cuando viewScreen es 4 -->
          <SolicitudesIndex v-if="viewScreen === 4" />
        </v-col>
        <!-- Columna para sección de Pólizas -->
        <v-col>
          <!-- Componente índice de pólizas, visible cuando viewScreen es 5 -->
          <PolizasIndex v-if="viewScreen === 5" />
        </v-col>
        <!-- Columna para sección de Clientes -->
        <v-col>
          <!-- Componente índice de clientes, visible cuando viewScreen es 6 -->
          <ClientesIndex v-if="viewScreen === 6" />
        </v-col>
      </div>
    </v-container>
  </common-card>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommonCard from "../../../../../components/commonComponents/CommonCard.vue";
import CardDashboard from "./components/CardDashboard.vue";
import BusquedaDetalle from "./components/BusquedaDetalle.vue";
import TablaAlianza from "./components/TablaAlianza.vue";
import CotizacionesIndex from "../../../hub/cotizaciones/Dasboard/CotizacionesIndex.vue";
import SolicitudesIndex from "../../../hub/solicitudes/Dasboard/SolicitudesIndex.vue";
import PolizasIndex from "../../../hub/polizas/Dashboard/PolizasIndex.vue";
import ClientesIndex from "../../../hub/clientes/Dashboard/ClientesIndex.vue";
import AgregarAsesor from "../AgregarAsesor.vue";
import DataAlianzaPanel from "./components/DataAlianzaPanel.vue";
import DocumentosAlianza from "./components/DocumentosAlianza.vue";
import ModalEditarMarcaAlianza from "../ModalEditarMarcaAlianza.vue";
import { statusMapAlianza, defaultHeaders } from "./utils/constants.js";
// import SecurityDataPanel from "../../../perfiles/components/securityData/SecurityDataPanel.vue";

export default {
  components: {
    CommonCard,
    CardDashboard,
    BusquedaDetalle,
    TablaAlianza,
    CotizacionesIndex,
    SolicitudesIndex,
    PolizasIndex,
    ClientesIndex,
    AgregarAsesor,
    DataAlianzaPanel,
    DocumentosAlianza,
    ModalEditarMarcaAlianza,
    // SecurityDataPanel,
  },
  name: "PerfilAlianza",
  data() {
    return {
      agentCorpId: "",
      openEditBannerModal: false,
      openEditLogoModal: false,
      openEditConfigModal: false,
      isAdminAgent: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      filter: "",
      alianza: [],
      countCotizaciones: "",
      countSolicitudes: "",
      countEmisiones: "",
      countAgente: "",
      countPrimaNeta: "",
      countCliente: "",
      nombreAlianza: "",
      viewScreen: 1,
      coverImage: "",
      logo: "",
      color: "",
      alianzaIdFromRoute: null,
      alianzaData: {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      routesConfigPassword: {
        route: "/v1/agente/admin/password/update",
        action: "POST",
        uuidCambio: "",
      },
      statusMap:statusMapAlianza,
      headers: defaultHeaders
    };
  },
  methods: {
    adminagent() {
      // if a role is this.adminagen, is true
      this.roles.forEach((element) => {
        if (element.rol == "ADMINAGENT") {
          this.isAdminAgent = true;
        }
      });
    },
    // ----------------- Method -----------------
    getAgentCorpId() {
      console.log("responseee working");
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/agent/get-agent-corp/${this.alianzaData.uuid}`, config)
        .then((response) => {
          response;
          console.log("responseee aqui", response);
          this.agentCorpId = response.data.uuid;
        })
        .catch((error) => {
          console.error("errorrr", error);
        });
    },
    loadTableData() {
      this.getListByFilter();
    },
    changeView(view) {
      this.viewScreen = view;
    },
    async getListByFilter() {
      const config = {
        headers: {
          Authorization: "Bearer" + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      try {
        const response = await mainAxios.post(
          `/v1/agente/list-filtro/${this.idParamOrStorage()}`,
          {
            filtroBusqueda: this.filter,
          },
          config
        );
        this.alianza = response.data;
      } catch (error) {
        console.error("error", error);
      } finally {
        this.loading = false;
      }
    },
    updateFilter(value) {
      this.filter = value;
      this.getListByFilter();
    },
    async getCountSolicitudes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/solicitud/count/${this.idParamOrStorage()}`,
          config
        );
        this.countSolicitudes = data.totalSolicitudes;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getCountEmisiones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/emision/count/${this.idParamOrStorage()}`,
          config
        );
        this.countEmisiones = data.totalEmisiones;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getCountCotizaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/cotizacion/count/${this.idParamOrStorage()}`,
          config
        );
        this.countCotizaciones = data.totalCotizaciones;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountAgente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/agente/count/${this.idParamOrStorage()}`,
          config
        );
        this.countAgente = data.totalAgentes;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountPrimaNeta() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/emision/sum-primas/${this.idParamOrStorage()}`,
          config
        );
        this.countPrimaNeta = data.sumPrimas;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountClientes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/cliente/count/${this.idParamOrStorage()}`,
          config
        );
        this.countCliente = data.totalClientes;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getTenant() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const response = await mainAxios.get(
        `/v1/alianza/id/${this.idParamOrStorage()}`,
        config
      );
      this.coverImage = response.data.coverImage;
      this.logo = response.data.logoImage;
      this.color = response.data.mainColor;
      this.nombreAlianza = response.data.nameAlliance;
      this.alianzaData = response.data;
      this.getAgentCorpId();
      // se guarda en localstorage el color de la alianza, el logo y la imagen de portada para usarlo en otros componentes
      localStorage.setItem("mainColorAlianza", this.color);
      localStorage.setItem("allianceLogo", this.logo);
      localStorage.setItem("allianceCover", this.coverImage);
      this.routesConfigPassword.uuidCambio = response.data.uuid;
      console.log(
        "response",
        response,
        this.alianzaData,
        this.routesConfigPassword
      );
      if (this.roles[0].rol === "ADMINAGENT") {
        localStorage.setItem("rolNotAdmin", "AGENTCORP");
      }
    },

    idParamOrStorage() {
      return this.alianzaIdFromRoute || localStorage.alianzaId;
    },
  },

  async mounted() {
    this.alianzaIdFromRoute =
      this.$route.params.alianza_id || localStorage.alianzaId;
    // this.isAdminAgent = this.roles === "ADMINAGENT";
    this.adminagent();
    await this.getTenant();
    await this.getCountSolicitudes();
    await this.getCountEmisiones();
    await this.getCountCotizaciones();
    await this.getCountAgente();
    await this.getCountPrimaNeta();
    await this.getCountClientes();
    await this.getListByFilter();
  },
};
</script>

<style scoped>
.profile-wrapper {
  /* Posicionamiento relativo para elementos internos */
  position: relative;
  /* Agrega espacio en la parte inferior de 120px */
  padding-bottom: 120px;
  /* Asegura que el contenedor tenga al menos la altura total de la ventana */
  min-height: 100vh;
}

/* Contenedor de contenido dinámico */
.dynamic-content-wrapper {
  /* Posicionamiento relativo para elementos internos */
  position: relative;
}

.cover-image {
  height: 200px;
  background-color: #e0e0e0;
}

.v-avatar {
  border: 4px solid white;
}

h2 {
  font-size: 27px;
}

.id-container {
  padding: 8px 0;
}

.edit-button-container {
  display: flex;
  align-items: center;
  padding-right:  30px;
}

.edit-profile-btn {
  margin-left: 16px;
  padding: 0 20px;
  height: 36px;
}

.responsive-icon {
  font-size: 20px;
}

@media (max-width: 480px) {
  .profile-wrapper {
    padding-bottom: 180px;
    /* Aún más espacio en dispositivos pequeños */
  }

  .responsive-icon {
    font-size: 14px;
  }

  .edit-profile-btn {
    padding: 0 12px;
    margin-left: 8px;
  }
}
</style>
