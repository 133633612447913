const listaDesplegable = (data) => {
  let result = [];
  data == "ADMIN"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },

                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
                {
                  title: "Chats Leads",
                  route: "/chats-leads",
                },
                {
                  title: "Chats clientes",
                  route: "/chats-clientes",
                },
                {
                  title: "Leads ECI",
                  route: "/leads",
                },
                {
                  title: "Clientes ECI",
                  route: "/clientes-eci",
                },
                {
                  title: "Servicio Inbound ECI",
                  route: "/inbound",
                },
                {
                  title: "Recicle Leads ECI",
                  route: "/leads/recicle-lead",
                },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Prospectos",
                  route: "/lista/prospectos",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Asesores Digitales",
                  route: "/usuarios/agentes",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Alianzas Corporativas",
                  route: "/administracion/alianzas",
                },
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de campañas",
                  route: "/campaings/table",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de correos",
                  route: "/emails/table",
                  //route: '/proximamente',
                },
                {
                  title: "Administrador de plantillas",
                  route: "/administacion/plantillas",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                {
                  title: "Reporte de pago de comisiones aplicados",
                  route: "/reporte-pago-comisiones-aplicados",
                },
                {
                  title: "Reporte dispersión de cuotas a asesores",
                  route: "/reporte-dispercion-asesores",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
                {
                  title: "Atracción de talento",
                  route: "/atraccion-talento",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
                {
                  title: "Reporte de Marcación",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Marcación Simple",
                  route: "/reporte/marcacion-simple",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes PosVenta",
              items: [
                {
                  title: "Solicitudes Inbound",
                  route: "/proximamente",
                },
                {
                  title: "Solicitudes Emails",
                  route: "/reporte/solicitudes-email",
                },
                {
                  title: "Llamadas inbound",
                  route: "/reporte/llamadas-inbound",
                },
              ],
            },
            // {
            //   action: "mdi-clipboard-text-search-outline",
            //   title: "Reporte de Partners",
            //   items: [
            //     {
            //       title: "Reporte de Emisiones tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-emisiones",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte de Leads tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-leads",
            //       //route: '/proximamente',
            //     },
            //     // {
            //     //   title: 'Reporte Toques Call Center tuSeguro.com',
            //     //   //route: '/callCenter/reporteLeadsTuseguro',
            //     //   route: '/proximamente',
            //     // },
            //     {
            //       title: "Reporte Leads-Oportunidades tuSeguro.com",
            //       route: "/callCenter/reporteLeadsTuseguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Abandono Leads TuSeguro",
            //       route: "/landings/reporteAbandonoLeads/tuSeguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Leads Coru-AIG",
            //       route: "/administrador/reporte-coru-aig",
            //       //route: '/proximamente',
            //     },
            //   ],
            // },
          ],
        },
      ])
    : data == "ADMINAGENT"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },
                // {
                //   title: "Leads Segurify",
                //   route: "/leads",
                // },
                // {
                //   title: "Servicio Inbound",
                //   route: "/inbound",
                // },
                // {
                //   title: "Recicle Leads",
                //   route: "/leads/recicle-lead",
                // },
                {
                  title: "Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
              ],
            },
            // {
            //   // action: "mdi-account-box-multiple-outline",
            //   title: "Agentes asignados",
            //   items: [
            //     { title: "Agentes asignados", route: "/usuarios/agentes-asignados" },
            //   ],
            // },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Prospectos",
                  route: "/lista/prospectos",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Asesores Digitales",
                  route: "/usuarios/agentes",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Alianzas Corporativas",
                  route: "/administracion/alianzas",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte de llamadas inbound",
                  route: "/reporte/llamadas-inbound",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "MANAGER"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },
                // {
                //   title: "Leads Segurify",
                //   route: "/leads",
                // },
                // {
                //   title: "Servicio Inbound",
                //   route: "/inbound",
                // },
                // {
                //   title: "Recicle Leads",
                //   route: "/leads/recicle-lead",
                // },
                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Asesores Digitales",
                  route: "/usuarios/agentes",
                  //route: '/proximamente',
                },
                { title: "Agentes asignados",
                  route: "/usuarios/agentes-asignados" }
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "AGENT"
    ? (result = [
        {
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                {
                  title: "Mi hub",
                  route: "/inicio",
                },
                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
            // {
            //   action: "mdi-clipboard-text-multiple",
            //   title: "Reportes",
            //   items: [
            //     {
            //       title: "Reporte Pólizas Emitidas (Anterior)",
            //       route: "/polizas-emitidas",
            //     },
            //     {
            //       title: "Cuotas de Pólizas Activas",
            //       route: "/cobranza-polizas-activas",
            //     },
            //   ],
            // },
            // {
            //   action: "mdi-account-circle",
            //   active: true,
            //   title: "Cotizaciones",
            //   items: [
            //     {
            //       title: "Coche Seguro",
            //       route: "/cotizacion/vehiculos ",
            //     },
            //     {
            //       title: "Mascota Segura",
            //       route: "/cotizacion/mascota",
            //     },
            //     {
            //       title: "Medico Seguro",
            //       route: "/cotizacion/medico",
            //     },
            //     {
            //       title: "Celular Seguro",
            //       route: "/cotizacion/celular",
            //     },

            //   ],
            // },
            // {
            //   action: "mdi-account-circle",
            //   active: true,
            //   title: "Dashboards",
            //   items: [
            //     {
            //       title: "Cotizaciónes",
            //       route: "/main-hub",
            //       prop: 4,
            //     },
            //     {
            //       title: "Solicitudes",
            //       route: "/main-hub",
            //       prop: 1,
            //     },
            //     {
            //       title: "Pólizas",
            //       route: "/main-hub",
            //       prop: 1,
            //     },
            //     {
            //       title: "Clientes",
            //       route: "/main-hub",
            //       prop: 1,
            //     },
            //   ],
            // },
          ],
        },
      ])
    : data == "ADMINCC"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },

                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
                {
                  title: "Leads ECI",
                  route: "/leads",
                },
                {
                  title: "Servicio Inbound ECI",
                  route: "/inbound",
                },
                {
                  title: "Recicle Leads ECI",
                  route: "/leads/recicle-lead",
                },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de campañas",
                  route: "/campaings/table",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de correos",
                  route: "/emails/table",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                // {
                //   title: "SLA de Solicitudes",
                //   route: "/sla-solicitudes",
                // },
                // {
                //   title: "Solicitudes con Pólizas",
                //   route: "/solicitudes-con-polizas",
                // },
                // {
                //   title: "Solicitudes Pendientes de Aprobación",
                //   route: "/solicitudes-pendientes-aprobacion",
                // },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Reporte de pago de comisiones aplicados",
                  route: "/reporte-pago-comisiones-aplicados",
                },
                {
                  title: "Reporte dispersión de cuotas a asesores",
                  route: "/reporte-dispercion-asesores",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
                {
                  title: "Reporte de Marcación",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Marcación Simple",
                  route: "/reporte/marcacion-simple",
                },
              ],
            },
            // {
            //   action: "mdi-clipboard-text-search-outline",
            //   title: "Reporte de Partners",
            //   items: [
            //     {
            //       title: "Reporte de Emisiones tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-emisiones",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte de Leads tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-leads",
            //       //route: '/proximamente',
            //     },
            //     // {
            //     //   title: 'Reporte Toques Call Center tuSeguro.com',
            //     //   //route: '/callCenter/reporteLeadsTuseguro',
            //     //   route: '/proximamente',
            //     // },
            //     {
            //       title: "Reporte Leads-Oportunidades tuSeguro.com",
            //       route: "/callCenter/reporteLeadsTuseguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Abandono Leads TuSeguro",
            //       route: "/landings/reporteAbandonoLeads/tuSeguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Leads Coru-AIG",
            //       route: "/administrador/reporte-coru-aig",
            //       //route: '/proximamente',
            //     },
            //   ],
            // },
          ],
        },
      ])
    : data == "MANAGERCC"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },

                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
                {
                  title: "Leads ECI",
                  route: "/leads",
                },
                {
                  title: "Servicio Inbound ECI",
                  route: "/inbound",
                },
                {
                  title: "Recicle Leads ECI",
                  route: "/leads/recicle-lead",
                },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de campañas",
                  route: "/campaings/table",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de correos",
                  route: "/emails/table",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                // {
                //   title: "SLA de Solicitudes",
                //   route: "/sla-solicitudes",
                // },
                // {
                //   title: "Solicitudes con Pólizas",
                //   route: "/solicitudes-con-polizas",
                // },
                // {
                //   title: "Solicitudes Pendientes de Aprobación",
                //   route: "/solicitudes-pendientes-aprobacion",
                // },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
                {
                  title: "Reporte de Marcación",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Marcación Simple",
                  route: "/reporte/marcacion-simple",
                },
              ],
            },
            // {
            //   action: "mdi-clipboard-text-search-outline",
            //   title: "Reporte de Partners",
            //   items: [
            //     {
            //       title: "Reporte de Emisiones tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-emisiones",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte de Leads tuSeguro.com",
            //       route: "/administrador/reporte-tuSeguro-leads",
            //       //route: '/proximamente',
            //     },
            //     // {
            //     //   title: 'Reporte Toques Call Center tuSeguro.com',
            //     //   //route: '/callCenter/reporteLeadsTuseguro',
            //     //   route: '/proximamente',
            //     // },
            //     {
            //       title: "Reporte Leads-Oportunidades tuSeguro.com",
            //       route: "/callCenter/reporteLeadsTuseguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Abandono Leads TuSeguro",
            //       route: "/landings/reporteAbandonoLeads/tuSeguro",
            //       //route: '/proximamente',
            //     },
            //     {
            //       title: "Reporte Leads Coru-AIG",
            //       route: "/administrador/reporte-coru-aig",
            //       //route: '/proximamente',
            //     },
            //   ],
            // },
          ],
        },
      ])
    : data == "AGENTCC"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },

                // {
                //   title: "Mis Cotizaciones",
                //   route: "/main-hub/4",
                //   //route: '/proximamente',
                // },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
                {
                  title: "Leads ECI",
                  route: "/leads",
                },
              ],
            },
          ],
        },
      ])
    : data == "DIGITAL"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Cotizaciones Autos",
                  route: "/mis-cotizaciones",
                },
                {
                  title: "Mis Cotizaciones Celular",
                  route: "/mis-cotizaciones-celular",
                },
                {
                  title: "Leads segurify",
                  route: "/leads",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },

                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
                {
                  title: "Atracción de talento",
                  route: "/atraccion-talento",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
                {
                  title: "Reporte de Marcación Simple",
                  route: "/reporte/marcacion-simple",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search-outline",
              title: "Reporte de Partners",
              items: [
                {
                  title: "Reporte de Emisiones tuSeguro.com",
                  route: "/administrador/reporte-tuSeguro-emisiones",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte de Leads tuSeguro.com",
                  route: "/administrador/reporte-tuSeguro-leads",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte Leads-Oportunidades tuSeguro.com",
                  route: "/callCenter/reporteLeadsTuseguro",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte Abandono Leads TuSeguro",
                  route: "/landings/reporteAbandonoLeads/tuSeguro",
                },
              ],
            },
          ],
        },
      ])
    : data == "DIRECTOR"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Cotizaciones Autos",
                  route: "/mis-cotizaciones",
                },
                {
                  title: "Mis Cotizaciones Celular",
                  route: "/mis-cotizaciones-celular",
                },
                {
                  title: "Leads segurify",
                  route: "/leads",
                },
                {
                  title: "Solicitudes",
                  route: "/solicitudes",
                  //route: '/proximamente',
                },
                {
                  title: "Clientes",
                  route: "/lista-clientes",
                  //route: '/proximamente',
                },
                {
                  title: "Pólizas",
                  route: "/mis-emisiones",
                  //route: '/proximamente',
                },
                {
                  title: "Leads",
                  route: "/reporte/leads",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
                {
                  title: "Atracción de talento",
                  route: "/atraccion-talento",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
              ],
            },
          ],
        },
      ])
    : data == "DIRECTO"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },
                // {
                //   title: "Leads Segurify",
                //   route: "/leads",
                // },
                // {
                //   title: "Servicio Inbound",
                //   route: "/inbound",
                // },
                // {
                //   title: "Recicle Leads",
                //   route: "/leads/recicle-lead",
                // },
                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "FINANZAS" || data == "COBRANZA"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },
                // {
                //   title: "Leads Segurify",
                //   route: "/leads",
                // },
                // {
                //   title: "Servicio Inbound",
                //   route: "/inbound",
                // },
                // {
                //   title: "Recicle Leads",
                //   route: "/leads/recicle-lead",
                // },
                // {
                //   title: "Mis Cotizaciones",
                //   route: "/main-hub/4",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Solicitudes",
                //   route: "/main-hub/1",
                //   //route: '/proximamente',
                // },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                // {
                //   title: "SLA de Solicitudes",
                //   route: "/sla-solicitudes",
                // },
                // {
                //   title: "Solicitudes con Pólizas",
                //   route: "/solicitudes-con-polizas",
                // },
                // {
                //   title: "Solicitudes Pendientes de Aprobación",
                //   route: "/solicitudes-pendientes-aprobacion",
                // },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                // {
                //   title: "Cartera de Clientes",
                //   route: "/proximamente",
                // },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                // {
                //   title: "Reporte Pólizas Autos Masivos (Anterior)",
                //   route: "/callcenter/reporte-emisiones-offline",
                // },
                // {
                //   title: "Reporte Pólizas Emitidas (Anterior)",
                //   route: "/polizas-emitidas",
                // },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                {
                  title: "Reporte de pago de comisiones aplicados",
                  route: "/reporte-pago-comisiones-aplicados",
                },
                {
                  title: "Reporte dispersión de cuotas a asesores",
                  route: "/reporte-dispercion-asesores",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "OPERACIONES" || data == "MESADECONTROL"
    ? (result = [
        {
          title: "Inicio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                // {
                //   title: "Mis Cotizaciones Autos",
                //   route: "/mis-cotizaciones",
                // },
                // {
                //   title: "Mis Cotizaciones Celular",
                //   route: "/mis-cotizaciones-celular",
                // },
                // {
                //   title: "Leads Segurify",
                //   route: "/leads",
                // },
                // {
                //   title: "Servicio Inbound",
                //   route: "/inbound",
                // },
                // {
                //   title: "Recicle Leads",
                //   route: "/leads/recicle-lead",
                // },
                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
                // {
                //   title: "Cuotas de Pólizas",
                //   route: "/cuotas-hub",
                //   //route: '/proximamente',
                // },
                // {
                //   title: "Mis Comisiones",
                //   route: "/lista-comisiones",
                //   // route: '/proximamente',
                // },

                // {
                //   title: "Mis Productos",
                //   route: "/lista-productos",
                //   //route: '/proximamente',
                // },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Asesores Digitales",
                  route: "/usuarios/agentes",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                // {
                //   title: 'Pólizas con cobranza Vencida',
                //   route: '/proximamente',
                // },
                // {
                //   title: 'Cuotas Pagadas',
                //   route: '/cuotas-pagadas',
                // },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "OPERACIONESINBOUND" || data == "OPERACIONESEMAILS"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Servicio Inbound",
                  route: "/inbound",
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
          ],
        },
        {
          action: "mdi-account-box-multiple-outline",
          title: "Adiministración",
          items: [
            {
              title: "Administrador de plantillas",
              route: "/administacion/plantillas",
            },
          ],
        },
      ])
    : data == "OPERADOR"
    ? (result = [
        {
          title: "Mi espacio",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                {
                  title: "Mi perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Cotizaciones",
                  route: "/main-hub/4",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/main-hub/2",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
              ],
            },
          ],
        },
      ])
    : data == "OPERADORINBOUND" || data == "OPERADOREMAILS"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Servicio Inbound",
                  route: "/inbound",
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes PosVenta",
              items: [
                {
                  title: "Solicitudes Inbound",
                  route: "/proximamente",
                },
                {
                  title: "Solicitudes Emails",
                  route: "/reporte/solicitudes-email",
                },
                {
                  title: "Llamadas inbound",
                  route: "/reporte/llamadas-inbound",
                },
              ],
            },
          ],
        },
      ])
    : data == "AGENTPARTNER"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-clipboard-text-search-outline",
              active: true,
              title: "Reporte de Partners",
              items: [
                {
                  title: "Mis Cotizaciones Autos",
                  route: "/mis-cotizaciones",
                },
                {
                  title: "Mis Cotizaciones Celular",
                  route: "/mis-cotizaciones-celular",
                },
                {
                  title: "Leads segurify",
                  route: "/leads",
                },
                {
                  title: "Reporte de Emisiones tuSeguro.com",
                  route: "/administrador/reporte-tuSeguro-emisiones",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte de Leads tuSeguro.com",
                  route: "/administrador/reporte-tuSeguro-leads",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte Leads-Oportunidades tuSeguro.com",
                  route: "/callCenter/reporteLeadsTuseguro",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte Abandono Leads TuSeguro",
                  route: "/landings/reporteAbandonoLeads/tuSeguro",
                  //route: '/proximamente',
                },
                {
                  title: "Reporte Leads Coru-AIG",
                  route: "/administrador/reporte-coru-aig",
                  //route: '/proximamente',
                },
              ],
            },
          ],
        },
      ])
    : data == "COBRANZACC"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Cotizaciones Autos",
                  route: "/mis-cotizaciones",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Cotizaciones Celular",
                  route: "/mis-cotizaciones-celular",
                },
                {
                  title: "Leads Segurify",
                  route: "/leads",
                },
                {
                  title: "Servicio Inbound",
                  route: "/inbound",
                },
                {
                  title: "Mis Clientes",
                  route: "/lista-clientes",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/mis-emisiones",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search-outline",
              active: true,
              title: "Reporte de Partners",
              items: [
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
              ],
            },
          ],
        },
      ])
    : data == "BACKOFFICE"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes",
              items: [
                {
                  title: "SLA de Solicitudes",
                  route: "/sla-solicitudes",
                },
                {
                  title: "Solicitudes con Pólizas",
                  route: "/solicitudes-con-polizas",
                },
                {
                  title: "Solicitudes Pendientes de Aprobación",
                  route: "/solicitudes-pendientes-aprobacion",
                },
                {
                  title: "Cartera de Agentes",
                  route: "/cartera-agentes",
                },
                {
                  title: "Cartera de Clientes",
                  route: "/proximamente",
                },
                {
                  title: "Reporte de Pólizas",
                  route: "/reporte/emisiones",
                },
                {
                  title: "Reporte Pólizas Autos Masivos (Anterior)",
                  route: "/callcenter/reporte-emisiones-offline",
                },
                {
                  title: "Reporte Pólizas Emitidas (Anterior)",
                  route: "/polizas-emitidas",
                },
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
                {
                  title: "Cuotas de Pólizas Activas",
                  route: "/cobranza-polizas-activas",
                },
                {
                  title: "Cuotas Canceladas",
                  route: "/cuotas-canceladas",
                },
                {
                  title: "Cuotas Vencidas",
                  route: "/cuotas-vencidas",
                },
                {
                  title: "Comisiones pendientes de Pago de la Aseguradora",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pendiente de Pago al agente",
                  route: "/proximamente",
                },
                {
                  title: "Comisiones Pagadas al Agente",
                  route: "/proximamente",
                },
                {
                  title: "Atracción de talento",
                  route: "/atraccion-talento",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Marcación Simple",
                  route: "/reporte/marcacion-simple",
                },
                {
                  title: "Reporte de Cotizaciones Landings",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
              ],
            },
          ],
        },
      ])
    : data == "RENOVACIONESCC"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Cotizaciones Autos",
                  route: "/mis-cotizaciones",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Cotizaciones Celular",
                  route: "/mis-cotizaciones-celular",
                },
                {
                  title: "Leads Segurify",
                  route: "/leads",
                },
                {
                  title: "Servicio Inbound",
                  route: "/inbound",
                },
                // {
                //   title: 'Mis Solicitudes',
                //   route: '/solicitudes',
                //   //route: '/proximamente',
                // },
                {
                  title: "Mis Clientes",
                  route: "/lista-clientes",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Pólizas",
                  route: "/mis-emisiones",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search",
              title: "Reportes ECI",
              items: [
                {
                  title: "Reportes leads",
                  route: "/reporte/leads",
                },
                {
                  title: "Reporte Call Back Time",
                  route: "/reporte/call-back-time",
                },
                {
                  title: "Reporte de Marcación de Leads",
                  route: "/reporte/marcacion",
                },
                {
                  title: "Reporte de Cotizaciones ECI",
                  route: "/administrador/reporte-clientes",
                },
                {
                  title: "Reporte de Abandono ECI Landing",
                  route: "/landings/reporteAbandonoLeads",
                },
                {
                  title: "Reporte de Tarificación Comparador",
                  route: "/reporte-tarificacion",
                },
              ],
            },
            {
              action: "mdi-clipboard-text-search-outline",
              active: true,
              title: "Reporte de Partners",
              items: [
                {
                  title: "Pólizas por renovar",
                  route: "/polizas-por-renovar",
                },
                {
                  title: "Pólizas renovadas",
                  route: "/polizas-renovadas",
                },
              ],
            },
          ],
        },
      ])
    : data == "RH"
    ? (result = [
        {
          action: "mdi-clipboard-text-multiple",
          title: "Reportes",
          items: [
            {
              title: "Atracción de talento",
              route: "/atraccion-talento",
            },
          ],
        },
      ])
    : data == "AGENTCCSPONSORS"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Inicio",
                  route: "/inicio",
                },
                {
                  title: "Mis Pólizas",
                  route: "/mis-emisiones",
                },
                {
                  title: "Leads segurify",
                  route: "/leads",
                },
              ],
            },
          ],
        },
      ])
    : data == "MESACONTROLINBOUND" ||
      data == "BACKOFFICEINBOUND" ||
      data == "BACKOFFICEEMAILS" ||
      data == "MESACONTROLEMAIL"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Servicio Inbound",
                  route: "/inbound",
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
          ],
        },
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              title: "Adiministración",
              items: [
                {
                  title: "Administrador de plantillas",
                  route: "/administacion/plantillas",
                },
                {
                  title: "Admin de correos",
                  route: "/emails/table",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes PosVenta",
              items: [
                {
                  title: "Solicitudes Inbound",
                  route: "/proximamente",
                },
                {
                  title: "Solicitudes Emails",
                  route: "/reporte/solicitudes-email",
                },
                {
                  title: "Llamadas inbound",
                  route: "/reporte/llamadas-inbound",
                },
              ],
            }
          ],
        },
      ])
    : data == "PROSPECTO"
    ? (result = [
        {
          items: [
            {
              action: "mdi-account",
              title: "Mi espacio",
              // items: [
              //   {
              //     title: "Mi espacio",
              //     route: "/perfil/prospecto",
              //   },
              // ],
            },
          ],
        },
      ])
    : data == "RECLUTA"
    ? (result = [
        {
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                // {
                //   title: "Inicio",
                //   route: "/inicio",
                // },
                {
                  title: "Mi perfil", // Título que se mostrará en la interfaz para este ítem
                  route: "/usuario/ver-micuenta", // Ruta a la que se redirigirá al hacer clic en "Mi perfil"
                },
              ],
            },
            {
              action: "mdi-account-box-multiple-outline",
              title: "Administración",
              items: [
                {
                  title: "Admin de Prospectos",
                  route: "/lista/prospectos",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Asesores Digitales",
                  route: "/usuarios/agentes",
                  //route: '/proximamente',
                },
              ],
            },
          ],
        },
      ])
    : data == "AGENTCORP"
    ? (result = [
        {
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Mi espacio",
              items: [
                {
                  title: "Mi espacio",
                  route: "/usuario/ver-micuenta",
                },
              ],
            },
            // {
            //   action: "mdi-clipboard-text-multiple",
            //   title: "Reportes",
            //   items: [
            //     {
            //       title: "Reporte Pólizas Emitidas (Anterior)",
            //       route: "/polizas-emitidas",
            //     },
            //     {
            //       title: "Cuotas de Pólizas Activas",
            //       route: "/cobranza-polizas-activas",
            //     },
            //   ],
            // },
          ],
        },
      ])
    : data == "MANAGEREMAILS"
    ? (result = [
        {
          title: "Cuenta",
          items: [
            {
              action: "mdi-account-circle",
              active: true,
              title: "Cuenta",
              items: [
                {
                  title: "Mi Perfil",
                  route: "/usuario/ver-micuenta",
                },
                {
                  title: "Mis Solicitudes",
                  route: "/main-hub/1",
                  //route: '/proximamente',
                },
                {
                  title: "Mis Clientes",
                  route: "/main-hub/3",
                  //route: '/proximamente',
                },
              ],
            },
          ],
        },
        {
          title: "Adiministración",
          items: [
            {
              action: "mdi-account-box-multiple-outline",
              active: true,
              title: "Adiministración",
              items: [
                {
                  title: "Administrador de plantillas",
                  route: "/administacion/plantillas",
                },
                {
                  title: "Admin de correos",
                  route: "/emails/table",
                  //route: '/proximamente',
                },
                {
                  title: "Admin de Usuarios",
                  route: "/usuarios",
                  //route: '/proximamente',
                },
              ],
            },
            {
              action: "mdi-clipboard-text-multiple",
              title: "Reportes PosVenta",
              items: [
                {
                  title: "Solicitudes Inbound",
                  route: "/proximamente",
                },
                {
                  title: "Solicitudes Emails",
                  route: "/reporte/solicitudes-email",
                },
                {
                  title: "Llamadas inbound",
                  route: "/reporte/llamadas-inbound",
                },
              ],
            }
          ],
        },
      ])
    : "";
  return result;
};

module.exports = listaDesplegable;
