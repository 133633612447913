<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <span class="titulo-header">Clientes</span>
      </v-col>
    </v-row>
    <div style="padding-top: 30px;" v-if="globalConfig.showMainHubGraphs">
        <stats-cliente ></stats-cliente >
    </div>
    <div style="padding-top: 30px;">
      <FiltersClientesIndex></FiltersClientesIndex>
    </div>
  </div>
</template>


<script>

import FiltersClientesIndex from './FiltersClientesIndex.vue';
import StatsCliente from './StatsCliente.vue';

export default {
  components: {
    FiltersClientesIndex,
    StatsCliente
  },

  data() {
    return {
      globalConfig:JSON.parse(localStorage.getItem('globalConfig'))
    };
  },
  methods: {
    checkAlianzaId() {
      const path = this.$route.path;
      if (path.includes('alianza/perfil/')) {
      this.globalConfig.showMainHubGraphs = false;
      }
    }
  },
  mounted() {
    this.checkAlianzaId();
  }
};
</script>