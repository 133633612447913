<template>
  <div>
    <v-row style="margin-top: 1px;" v-if="screen === 0">
      <v-col cols="12" v-if="rol != 'AGENTPARTNER'">
        <cotizacionesHUb v-if="rol != 'AGENTCCSPONSORS' && globalConfig.showQuotesDashboard"></cotizacionesHUb>
        <!-- comparador -->
        <solicitudHUB></solicitudHUB>
      </v-col>
    </v-row>
    <DocsValidadosView v-if="screen === 1" @updateScreen="screen = $event"></DocsValidadosView>
  </div>
</template>

<script>
import cotizacionesHUb from "../../components/homeComponents/cotizacionesHUB.vue";
import solicitudHUB from "../../components/homeComponents/solicitudHUB.vue";
import DocsValidadosView from "../pages/docsProspectos/DocsValidadosView.vue";
import { mainAxios } from '@/mainAxios.js';
export default {
  components: { cotizacionesHUb, solicitudHUB, DocsValidadosView },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      screen: 0, // propiedad para controlar la pantalla que se muestra
    };
  },
  methods: {
    async checkHasSeenScreen() {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.agenteAccessToken}` }
        };
        // obtener el UUID del agente
        const uuid = localStorage.getItem("agenteUuid");
        if (!uuid) throw new Error("No se encontró el UUID del agente");
        // obtener el agente
        const { data: agente } = await mainAxios.get(`/v1/agente/${uuid}`, config);
        if (!agente?.id) throw new Error("ID de agente no encontrado");
        // verificar si el agente ha visto la pantalla de bienvenida
        const { data } = await mainAxios.get(`/v1/agente/vista_bienvenida/${agente.id}`, config);
        // si ha visto la pantalla de bienvenida, mostrar la pantalla de documentos validados
        this.screen = data.vista_bienvenida ? 0 : 1;
      } catch (error) {
        console.error("Error en checkHasSeenScreen:", error.message || error);
      }
    }
  },
  computed: {},
  mounted() {
    this.checkHasSeenScreen();
  },
};
</script>
