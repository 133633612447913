export const statusMap = {
  "-1": { text: "Baja", color: "red" },
  "0": { text: "Nuevo", color: "blue" },
  "1": { text: "Activo", color: "#25A249" },
  "2": { text: "Inactivo", color: "#82898E" },
  defaultStatus: { text: "N/A", color: "black" },
};

export const headers = [
  { text: "ID", value: "id" },
  { text: "Estatus", value: "status" },
  { text: "Nombre", value: "nombreCompleto" },
  { text: "Teléfono", value: "celular" },
  { text: "Correo electrónico", value: "correo" },
  { text: "Fecha de registro", value: "fechaRegistro" },
  { text: "Fecha de asignación", value: "fechaAsignacion" },
  { text: "Ver Agente", value: "uuid", sortable: false },
];
