<template>
    <!-- Contenedor principal de la vista -->
    <v-container>
        <!-- Contenedor de la tarjeta para el contenido -->
        <v-card class="card-container">
            <v-row>
                <!-- Columna para la imagen, visible en pantallas medianas y más grandes -->
                <v-col cols="4" class="d-flex align-center">
                    <v-img class="d-none d-md-block" :src="image" cover height="100%" />
                </v-col>
                <!-- Columna para la información de la tarjeta, ajusta columnas según el tamaño de la pantalla -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="d-flex justify-center align-center"
                :class="colPaddingClass" >
                    <div class="card-info">
                        <!-- Logo de Interify -->
                        <v-img :src="interifyLogo" />
                        <!-- Imagen para vista móvil -->
                        <v-img class="image d-block d-md-none" :src="mobileImage" />

                        <!-- Sección para el ícono y texto de agradecimiento -->
                        <div class="icon-text">
                            <v-img :src="InterGif" />
                            <p>¡Muchas gracias!</p>
                        </div>
                        <!-- Texto informativo sobre la revisión de documentos -->
                        <div>
                            <p class="text">Estamos revisando tus documentos y en aproximadamente 24 horas podrás volver
                                para conocer
                                el
                                resultado de tu validación.
                            </p>
                            <p class="text">Mientras tanto, te invitamos a visitar <a href="https://interify.mx/"
                                    target="_blank">interify.mx</a> para conocer más sobre nuestra
                                plataforma.</p>
                            <p class="text-bottom">Agradecemos tu paciencia</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import image from '@/assets/ImageCheckingDocs.svg';
import InterGif from "@/assets/inteGif.gif";
import interifyLogo from "@/assets/interify.svg";
import mobileImage from "@/assets/checking-docs-mobile.svg";

export default {
    name: 'RevisandoDocsView',
    data() {
        return {
            image, // Imagen para vista de escritorio
            InterGif, // Imagen GIF para el ícono
            interifyLogo, // Imagen del logo
            mobileImage // Imagen para vista móvil
        }
    },
    computed: {
        colPaddingClass() {
            // Agrega clase de padding para vista móvil
            return this.$vuetify.breakpoint.smAndDown ? 'padding-mobile' : '';
        }
    }
};
</script>

<style scoped>
/* Estilo del contenedor principal */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #001B30;
}

@media (max-width: 600px) {
    .container {
        display: contents;
    }
}

/* Estilo del contenedor de la tarjeta */
.card-container {
    background-color: #F6F5F9;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    margin: 30px;
}

@media (max-width: 600px) {
    .card-container {
        display: contents;
    }
}

/* Estilo de la sección de ícono y texto */
.icon-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.icon-text img {
    width: 63px;
    height: 58px;
}

.icon-text p {
    font-size: 20px;
    font-weight: 700;
    color: #00A5DF !important;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

/* Estilo de la sección de información de la tarjeta */
.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 14px;
    background-color: #FFFFFF;
    margin: 25px 25px 25px 5px;
    gap: 36px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .card-info {
        margin: 0;
    }
}

/* Estilo del texto */
.text {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #093E4C !important;
}

.image {
    padding: 0;
}

.text-bottom {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #00A5DF !important;
}

/* Clase de padding para vista móvil */
.padding-mobile {
    padding: 0 30px 30px 30px;
}
</style>