<template>
  <div>
    <v-card
      class="mx-auto emails-card"
      color="white"
      width="100%"
      elevation="0"
    >
      <!-- Versión Desktop -->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <!-- Card title si NO está abierto -->
        <v-card-title
          v-if="!showEmails"
          class="desktop-title"
          @click="showAll"
        >
          <v-img
            :src="emailImg"
            max-width="43"
            class="email-icon theme--light"
            alt="Emails Icon"
          />
        </v-card-title>

        <!-- Card title si SÍ está abierto -->
        <v-card-title
          v-if="showEmails"
          class="desktop-title-open"
        >
          <v-row no-gutters>
            <v-col
              cols="10"
              class="d-flex align-center"
              @click="showAll"
            >
              <v-row align="center" no-gutters>
                <v-col cols="auto">
                  <v-img
                    :src="emailImg"
                    class="email-icon theme--light"
                    alt="Emails Icon"
                    max-width="25"
                  />
                </v-col>
                <v-col cols="auto">
                  <span class="email-title">Emails</span>
                </v-col>
              </v-row>
            </v-col>

            <!-- Ícono “Agregar Email” fijado a 50px -->
            <v-col
              v-if="rol != 'PROSPECTO' && canEdit"
              cols="2"
              class="d-flex justify-end align-center"
              @click="OpenDialogCreateEmail = true"
            >
              <v-img
                :src="AddIcon"
                alt="Agregar Icon"
                style="width: 50px;"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <!-- Contenido si NO está abierto -->
        <v-card-text v-if="!showEmails" class="text-center">
          <span class="email-text-title">Emails</span>
        </v-card-text>

        <!-- Contenido si SÍ está abierto -->
        <v-card-text v-if="showEmails">
          <div v-if="emails.length > 0" class="emails-list-container">
            <v-row
              v-for="(e, idx) in emails.slice(0, 3)"
              :key="idx"
            >
              <v-col cols="12" class="custom-list">
                {{ e.subject }}
                <span class="view-link" @click="OpenDialogShowEmails = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>

          <div v-if="emails.length > 0" class="text-center">
            <v-btn
              elevation="0"
              color="white"
              @click="OpenDialogShowEmails = true"
            >
              <span class="view-more-text">ver más</span>
            </v-btn>
          </div>

          <div v-else class="emails-empty-container text-center">
            <v-row>
              <v-col cols="12">
                <h3>No hay nada por el momento</h3>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>

      <!-- Versión Mobile -->
      <template v-else>
        <!-- Card title si NO está abierto -->
        <v-card-title
          v-if="!showEmails"
          class="mobile-title"
          @click="showAll"
        >
          <v-img
            :src="emailImg"
            max-width="43"
            class="email-icon theme--light"
            alt="Emails Icon"
          />
        </v-card-title>

        <!-- Card title si SÍ está abierto -->
        <v-card-title
          v-if="showEmails"
          class="mobile-title-open"
        >
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-row>
                <v-col cols="2">
                  <v-img
                    :src="emailImg"
                    class="email-icon theme--light"
                    alt="Emails Icon"
                    max-width="30"
                  />
                </v-col>
                <v-col cols="10">
                  <span class="email-text-title">Emails</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- Contenido si NO está abierto -->
        <v-card-text v-if="!showEmails" class="text-center">
          <span class="email-text-title">Emails</span>
        </v-card-text>

        <!-- Contenido si SÍ está abierto -->
        <v-card-text v-if="showEmails">
          <div v-if="emails.length > 0">
            <v-row
              v-for="(e, idx) in emails.slice(0, 3)"
              :key="idx"
            >
              <v-col cols="12" class="custom-list">
                {{ e.subject }}
                <span class="view-link" @click="OpenDialogShowEmails = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="emails.length > 0">
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  elevation="0"
                  color="white"
                  @click="OpenDialogShowEmails = true"
                >
                  <span class="view-more-text">ver más</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else class="emails-empty-container text-center">
            <v-row>
              <v-col cols="12">
                <h3>No hay nada por el momento</h3>
              </v-col>
            </v-row>
            <v-row class="mt-4 text-center">
              <v-col cols="12">
                <v-btn
                  outlined
                  color="#00a7e4"
                  @click="OpenDialogCreateEmail = true"
                  class="ma-3"
                  rounded
                >
                  Agregar Email
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>
    </v-card>

    <create-email-dialog
      :OpenDialogCreateEmail="OpenDialogCreateEmail"
      :emailRoutesConfig="emailRoutesConfig"
      @closeModal="OpenDialogCreateEmail = false"
      @success="getData"
    />
    <ShowEmailsDialog
      :emails="emails"
      :OpenDialogShowEmails="OpenDialogShowEmails"
      @closeModal="OpenDialogShowEmails = false"
      @openCreateModal="OpenDialogCreateDocument = true"
    />
  </div>
</template>

<script>
import CreateEmailDialog from "@/components/commonComponents/dialogs/CreateEmailDialog.vue";
import ShowEmailsDialog from "../dialogs/ShowEmailsDialog.vue";
import email from "@/assets/email.svg";
import addIcon from "@/assets/addIcon.svg";

export default {
  components: {
    CreateEmailDialog,
    ShowEmailsDialog,
  },
  data() {
    return {
      showEmails: false,
      OpenDialogCreateEmail: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      OpenDialogCreateDocument: false,
      OpenDialogShowEmails: false,
      emailImg: email,
      AddIcon: addIcon,
    };
  },
  props: {
    emails: Array,
    emailRoutesConfig: Object,
    openAll: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    openAll(val) {
      this.showEmails = val;
    },
  },
  methods: {
    getData() {
      this.$emit("getData");
    },
    showAll() {
      this.$emit("showAll", !this.showEmails);
    },
  },
};
</script>

<style scoped>
.emails-card {
  border-radius: 20px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
              0px 3px 4px 0px rgba(0, 0, 0, 0.14),
              0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.desktop-title,
.desktop-title-open,
.mobile-title,
.mobile-title-open {
  justify-content: center;
  cursor: pointer;
  min-height: 60px;
}

.email-icon {
  width: 40px;
  height: auto;
}

.email-title,
.email-text-title {
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 8px;
}

.custom-list {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0;
  background-color: aliceblue;
}

.view-link {
  color: #0070c0;
  cursor: pointer;
}

.view-more-text {
  color: #0070c0;
}

.emails-list-container {
  height: 180px !important;
}

.emails-empty-container {
  height: 216px !important;
}
</style>
