var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
        ? 'text-center active-color'
        : 'text-center inactive-color',attrs:{"cols":_vm.currentPorcentaje},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
          ? 'text-header-step-active'
          : 'text-header-step-no-active'},[_vm._v(" Solicitud")])]),_c('v-col',{class:_vm.tabReplace == 2
        ? 'text-center active-color'
        : 'text-center inactive-color',attrs:{"cols":"currentPorcentaje"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
          ? 'text-header-step-active'
          : 'text-header-step-no-active'},[_vm._v(" Cliente ")])]),_c('v-col',{class:_vm.tabReplace == 3
        ? 'text-center active-color'
        : 'text-center inactive-color',attrs:{"cols":"currentPorcentaje"},on:{"click":function($event){return _vm.tabReplaceSelect(3)}}},[_c('span',{class:_vm.tabReplace == 3
          ? 'text-header-step-active'
          : 'text-header-step-no-active'},[_vm._v(" SLA")])]),_c('v-col',{class:_vm.tabReplace == 4
        ? 'text-center active-color'
        : 'text-center inactive-color',attrs:{"cols":"currentPorcentaje"},on:{"click":function($event){return _vm.tabReplaceSelect(4)}}},[_c('span',{class:_vm.tabReplace == 4
          ? 'text-header-step-active'
          : 'text-header-step-no-active'},[_vm._v(" Beneficiarios")])])],1)],1),(_vm.tabReplace == 1)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('solicitud-info-index',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('EmisionDatosGeneralesIndex',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('required-solicitudes-info',{attrs:{"datosSolicitud":_vm.datosSolicitud,"operadores":_vm.operadores},on:{"getData":_vm.updateView}})],1)]):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('cliente-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('contact-cliente-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('domicilio-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',[(
        _vm.rol == 'MESACONTROLINBOUND' ||
        _vm.rol == 'OPERADORINBOUND' ||
        _vm.rol == 'OPERACIONESINBOUND' ||
        _vm.rol == 'ADMIN'
      )?_c('EmbeddedCliente',{attrs:{"telefonos":_vm.telefonosCliente,"cliente_id":_vm.datosSolicitud.solicitudBody.cliente_id,"is_solicitud":true}}):_vm._e()],1)]):_vm._e(),(_vm.tabReplace == 3)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('sla-solcitud',{attrs:{"datosSolicitud":_vm.datosSolicitud,"infoSLA":_vm.infoSLA}})],1)]):_vm._e(),(_vm.tabReplace == 4)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('BeneficiariosIndex',{attrs:{"beneficiarios":_vm.beneficiarios},on:{"getBeneficiarios":_vm.getBeneficiariosBySolicitudId,"closeModal":_vm.closeModalBeneficiario}})],1)]):_vm._e(),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }