<template>
    <common-card>
  <div style="height: 90%;">
    <!-- Display content in a row layout for medium screens and -->
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="font-montserrat d-flex">
        <v-col cols="12" md="7" class="pa-14 d-flex flex-column" style="gap:32px; border-radius: 20px;">
          <!-- Welcome message with GIF -->
          <div class="d-flex align-center" style="gap:10px">
            <img :src="InteGif" class="inte-gif" />
            <h1 class="custom-title">¡Bienvenido, {{ name }}!</h1>
          </div>
          <h2 class="custom-text">
            Estamos encantados de que te unas a nuestra red de expertos.
          </h2>
          <h3 class="custom-text-2 text-center">¡Es muy sencillo!</h3>
          <img :src="OnboardingImage" class="onboarding-image" />
        </v-col>
        <!-- Right column for document instructions -->
        <v-col cols="12" md="5" class="pa-16 d-flex flex-column justify-center"
          style="background-color: #F9FAFB; border-radius: 0 20px 20px 0;">
          <div class="d-flex flex-column" style=" gap: 32px;">
            <p class="custom-text-right">
              Para completar tu registro, necesitamos verificar tu identidad y
              experiencia. Ten a la mano la siguiente documentación y súbela
              para continuar.
            </p>
            <h4 class="custom-title-right">Documentación necesaria</h4>
            <document-item :documents="documents" />
            <div class="d-flex justify-end">
              <!-- Button to navigate to document upload page -->
              <button class="continue-button" @click="uploadDocRoute()">Continuar</button>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Mobile view using a carousel -->
      <v-carousel height="auto" v-else continuous show-arrows="hover" hide-delimiter-background
        style="padding-bottom: 32px;">
        <v-carousel-item>
          <div class="px-4 py-8 d-flex flex-column" style="gap:32px">
            <!-- Welcome message for mobile -->
            <div class="d-flex align-center" style="gap:10px">
              <img :src="InteGif" class="inte-gif" />
              <h1 class="custom-title">¡Bienvenido, {{ name }}!</h1>
            </div>
            <h2 class="custom-text">
              Estamos encantados de que te unas a nuestra red de expertos.
            </h2>
            <h3 class="custom-text-2 text-center">¡Es muy sencillo!</h3>
            <v-img :src="OnboardingImage" class="onboarding-image" />
          </div>
        </v-carousel-item>

        <!-- Second carousel item for document instructions -->
        <v-carousel-item>
          <div class="px-4 py-8 d-flex flex-column" style="background-color: #F9FAFB; border-radius: 20px; gap: 32px;">
            <div class="d-flex flex-column" style="gap: 16px;">
              <p class="custom-text-right">
                Para completar tu registro, necesitamos verificar tu identidad y
                experiencia.
              </p>
              <p class="custom-text-right">
                Ten a la mano la siguiente documentación y súbela para
                continuar.
              </p>
            </div>
            <h4 class="custom-title-right">Documentación necesaria</h4>
            <document-item :documents="documents" />
            <div class="d-flex justify-end">
              <!-- Button to navigate to document upload page -->
                <button class="continue-button" @click="uploadDocRoute()">Continuar</button>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
  </div>
    </common-card>
</template>

<script>
import { mainAxios } from "@/mainAxios";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import DocumentItem from "./components/DocumentItem.vue";
import OnboardingImage from "@/assets/onboarding_image.png";
import docsIcon from "@/assets/docsIcon.png";
import InteGif from "@/assets/inteGif.gif";

export default {
  name: "inicioProspectos",
  components: {
    CommonCard,
    DocumentItem,
  },
  data() {
    return {
      //image assets
      OnboardingImage: OnboardingImage,
      docsIcon: docsIcon,
      InteGif: InteGif,
      //Required documents list
      documents: [
        "Identificación: INE o pasaporte (vigente).",
        "Constancia de Situación Fiscal.",
        "Comprobante de Domicilio:",
        "Carátula de Cuenta Bancaria:",
        "Cédula de Agente de Seguros (opcional)",
        "RC de Agente (opcional)",
        "Documento de afiliación",
      ],
      name: "",
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
    };
  },
  methods: {
    // Get the prospect's name
    async getProspectName() {
      try {
        const response = await mainAxios.get(
          "/v1/prospecto/perfil",
          this.config
        );
        this.name = response.data.data.prospecto.nombre || "Usuario";
      } catch (error) {
        console.error("Error obteniendo el nombre del prospecto:", error);
      }
    },
    // Check if the user is an admin
    checkIsAdmin() {
      const roles = JSON.parse(localStorage.getItem("roles")) || [];
      return roles.some((role) => role.rol === "ADMINAGENT");
    },
    // Navigate to the document upload page
    uploadDocRoute() {
      this.$router.push("/upload-docs");
    },
  },
  mounted() {
    // If the user is an admin, redirect to the dashboard
    if(this.checkIsAdmin()){
      this.$router.push("/inicio");
      return;
    }
    this.getProspectName();
  },
};
</script>

<style scoped>
.custom-title {
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 120%;
  color: #000;

  @media (max-width: 1366px) {
    font-size:2rem;
  }

  @media (max-width: 960px) {
    font-size:2rem;
  }
}

.custom-text {
  font-size:2rem;
  font-weight: 800;
  line-height: 120%;
  color: #0070c0;

  @media (max-width: 1366px) {
    font-size: 1.375rem;
  }

  @media (max-width: 960px) {
    font-size:1.25rem;
    font-weight: 700;
  }
}

.custom-text-2 {
  font-size:1.25rem;
  font-weight: 700;
  line-height: 120%;
  color: #00a5df;

  @media (max-width: 1366px) {
    font-size: 1rem;
  }
}

.onboarding-image {
  height: 390px;
  width: 390px;
  cursor: pointer;
  display: flex;
  align-self: center;
  object-fit:contain;

  @media (max-width: 1366px) {
    width: 320px;
    height: 320px;
  }

  @media (max-width: 960px) {
    width: 280px;
    height: 280px;
  }
}

.custom-text-right {
  font-size:1rem;
  font-weight: 700;
  line-height: 166%;
  color: #000 !important;
  margin: 0 !important;

  @media (max-width: 1366px) {
    font-size: 0.75rem;
  }

  @media (max-width: 960px) {
    font-size: 0.75rem;
  }
}

.custom-title-right {
  font-size:1.5rem;
  font-weight: 800;
  line-height: 120%;
  color: #00a5df;

  @media (max-width: 1366px) {
    font-size: 1rem;
  }

  @media (max-width: 960px) {
    font-size:1rem;
  }
}

.docs-icon {
  height: 22px;
  width: 18px;
}

.custom-text-2-right {
  font-size:1rem;
  font-weight: 400;
  line-height: 120%;
  color: #303030;

  @media (max-width: 1366px) {
    font-size: 0.875rem;
  }

}

.continue-button {
  width: 130px;
  border-radius: 8px;
  font-size:1rem;
  font-weight: 700;
  line-height: normal;
  color: #f6f5f9;
  padding: 16px 22px;
  background-color: #00a5df;

  @media (max-width: 1366px) {
    font-size: 0.875rem;
    padding: 12px 18px;
  }
}

.inte-gif {
  width: 63px;
  height: 58px;

  @media (max-width: 1366px) {
    width: 50px;
    height: 45px;
  }
}

/deep/ .v-carousel__controls .v-btn {
  color: #eaecf0 !important;
}

/deep/ .v-carousel__controls .v-btn.v-btn--active {
  color: #00a5df !important;
}
</style>
