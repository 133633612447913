<template>
  <div class="pt-5">
    <v-data-table
      :items="items"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin asesores para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
          <v-col cols="12" md="4" style="text-align: end">
            <download-csv :data="columnsReport" name="polizas_tabla.csv">
              <v-btn color="transparent" elevation="0" small>
                <span style="color: #0070c0">Descargar</span>
                <v-icon small right class="mr-2" color="#0070C0"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
            </download-csv>
          </v-col>
        </v-row>
      </template>
      <template #item.id="{ item }">
        {{ item.id ? item.id : "Sin ID" }}
      </template>

      <template v-slot:item.status="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <v-chip
            :color="
              Object.keys(statusMap).includes(String(item.status))
                ? statusMap[String(item.status)].color
                : statusMap.defaultStatus.color
            "
            dark
          >
            {{
              Object.keys(statusMap).includes(String(item.status))
                ? statusMap[String(item.status)].text
                : statusMap.defaultStatus.text
            }}
          </v-chip>
        </div>
      </template>
      <template #item.nombreCompleto="{ item }">
        <span>{{
          item.nombreCompleto ? item.nombreCompleto : "Sin Nombre"
        }}</span>
      </template>
      <template #item.celular="{ item }">
        <span>{{ item.celular || item.telefono || "No disponible" }}</span>
      </template>
      <template #item.correoElectronico="{ item }">
        <div
          class="text-truncated"
          :title="item.correo ? item.correo : 'Sin Correo'"
        >
          {{ item.correo ? item.correo : "Sin Correo" }}
        </div>
      </template>
      <template #item.fechaRegistro="{ item }">
        <span>{{
          item.fechaRegistro ? item.fechaRegistro.split(" ")[0] : "Sin Fecha"
        }}</span>
      </template>
      <template v-slot:item.uuid="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <v-btn icon x-small @click="handleEditItem(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
      <!-- <template #item.ver="{ item }">
        <v-btn icon x-small @click="editItem(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template> -->

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Function,
      default: null,
    },
    statusMap: {
      type: Object,
    },
    headers: {
      type: Array,
      required: true,
    },
  },

  data() {
    //TODO: aplicar o no el sort
    return {
      columnsReport: [],
    };
  },

  methods: {
    //this method is used to use the editItem prop if it is passed, otherwise it uses the localEditItem method
    handleEditItem(item) {
      const editFn = this.editItem || this.localEditItem;
      const id = this.editItem ? item.id : item.uuid;
      editFn(id);
    },
    localEditItem(item) {
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },
    formatReport() {
      //transformMap to format the custom data
      const transformMap = {
        status: (e) => this.statusMap[e.status]?.text ?? "Sin llenar",
        celular: (e) => e.celular || e.telefono || "Sin llenar",
        fechaRegistro: (e) =>
          e.fechaRegistro ? e.fechaRegistro.split(" ")[0] : "Sin llenar",
      };

      //using reduce to create the dynamic object with the headers text and the data
      this.columnsReport = this.items.map((e) =>
        this.headers
        .filter(header => header.text !== "Ver Agente")
        .reduce((row, header) => {
          const key = header.value;
          row[header.text] = transformMap[key]
            ? transformMap[key](e)
            : e[key] ?? "Sin llenar";
          return row;
        }, {})
      );
    },
  },

  watch: {
    items: {
      handler() {
        this.formatReport();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-chip.v-size--default {
  height: auto;
  width: 70px;
  display: flex;
  justify-content: center;
}

.text-truncated {
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
