<template>
  <div>
    <v-card elevation="0">
      <div ref="chart"></div>
    </v-card>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";

export default {
  props: {
    clientes: {
      type: Object,
      default: () => ({ status: {} }),
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  mounted() {
    this.renderChart();
  },

  watch: {
    clientes: {
      deep: true,
      handler() {
        if (this.chart) {
          const series = Object.values(this.clientes.status || {});
          this.chart.updateSeries(series);
        }
      },
    },
  },

  methods: {
    renderChart() {
      const series = Object.values(this.clientes.status || {});
      const labels = Object.keys(this.clientes.status || {});
      const colors = ["#FF7F50", "#D0E6BA", "#20B2AA", "#A9C0D3"];

      const options = {
        series: series,
        chart: {
          height: 350,
          type: "radialBar",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        plotOptions: {
          radialBar: {
            offsetY: -30,
            startAngle: 0,
            endAngle: 350,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
            },
            track: {
              show: true,
              background: "#f2f2f2",
              opacity: 0.5,
              strokeWidth: "95%",
              margin: 5,
              dropShadow: {
                enabled: false,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: "Total",
                fontSize: "14px",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                },
              },
            },
            cornerRadius: "55%",
            strokeLinecap: "round",
            trackSize: "95%",
            barSize: "85%",
            distributed: false,
          },
        },
        stroke: {
          lineCap: "round",
          width: 4,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        labels: labels,
        colors: colors.slice(0, labels.length),
        legend: {
          show: true,
          floating: false,
          position: "right",
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: 50, // Valor base para pantallas grandes
          fontSize: "14px",
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 8,
            radius: 12,
          },
          formatter: function (seriesName) {
            return seriesName;
          },
          itemMargin: {
            vertical: 8,
          },
        },
        responsive: [
          {
            // Para pantallas muy pequeñas, ocultamos la leyenda (y de todas formas se oculta la gráfica en la vista)
            breakpoint: 768,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      };

      this.chart = new ApexCharts(this.$refs.chart, options);
      this.chart.render();
    },
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
:deep(.apexcharts-radialbar-track) path {
  stroke-linecap: round;
}

:deep(.apexcharts-radialbar) path {
  stroke-linecap: round;
}
</style>
