<template>
  <div>
    <!-- Contenedor donde se renderizará el gráfico -->
    <div ref="chart"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: "CotizacionesStatusGraphic",
  props: {
    // Se espera que 'cotizaciones' tenga las claves "Mascota", "Coche", "Médico", "Celular".
    cotizaciones: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    // Si los datos de 'cotizaciones' cambian, se actualiza la serie del gráfico.
    cotizaciones: {
      deep: true,
      handler() {
        if (this.chart) {
          // Orden de afuera hacia adentro: [Mascota, Coche, Médico, Celular]
          const series = [
            this.cotizaciones["Mascota"] || 0,
            this.cotizaciones["Coche"] || 0,
            this.cotizaciones["Médico"] || 0,
            this.cotizaciones["Celular"] || 0
          ];
          // Actualiza únicamente los valores (series) en la gráfica
          this.chart.updateSeries(series);
        }
      }
    }
  },
  methods: {
    renderChart() {
      // Orden de afuera hacia adentro: [Mascota, Coche, Médico, Celular]
      const series = [
        this.cotizaciones["Mascota"] || 0,
        this.cotizaciones["Coche"] || 0,
        this.cotizaciones["Médico"] || 0,
        this.cotizaciones["Celular"] || 0
      ];

      const options = {
        series,
        chart: {
          // Ocupa el 100% del ancho de su contenedor
          width: '100%',
          height: 400,
          type: 'radialBar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: -30,
            startAngle: 0,
            endAngle: 350,
            hollow: {
              margin: 5,
              size: '30%', // Tamaño base del hueco
              background: 'transparent'
            },
            track: {
              show: true,
              background: '#f2f2f2',
              opacity: 0.5,
              strokeWidth: '95%',
              margin: 5, // margen entre tracks
              dropShadow: {
                enabled: false
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              },
              total: {
                show: true,
                label: 'Total',
                fontSize: '14px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                }
              }
            },
            cornerRadius: '55%',
            strokeLinecap: 'round',
            trackSize: '95%',
            barSize: '85%', // grosor base de la barra
            distributed: false
          }
        },
        stroke: {
          lineCap: 'round',
          width: 4
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        labels: ['Mascota', 'Coche', 'Médico', 'Celular'],
        colors: ['#FF7F50', '#D0E6BA', '#20B2AA', '#A9C0D3'],

        // Leyenda siempre a la derecha
        legend: {
          show: true,
          floating: false,
          position: 'right',
          horizontalAlign: 'center',
          offsetX: 0,
          offsetY: 50,
          fontSize: '14px',
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 8,
            radius: 12
          },
          formatter: function (seriesName) {
            return seriesName; // Solo el texto
          },
          itemMargin: {
            vertical: 8
          }
        },

        // Ajustes responsivos para que, a medida que se reduce el espacio (por zoom o viewport),
        // se vaya encogiendo la rosca y la leyenda, evitando que se encimen.
        responsive: [
          {
            // < 1400px
            breakpoint: 1400,
            options: {
              chart: {
                height: 380
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '28%'
                  },
                  barSize: '80%'
                }
              },
              legend: {
                fontSize: '13px'
              }
            }
          },
          {
            // < 1200px
            breakpoint: 1200,
            options: {
              chart: {
                height: 350
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '25%'
                  },
                  barSize: '75%'
                }
              },
              legend: {
                fontSize: '12px'
              }
            }
          },
          {
            // < 992px
            breakpoint: 992,
            options: {
              chart: {
                height: 320
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '22%'
                  },
                  barSize: '70%'
                }
              },
              legend: {
                fontSize: '11px'
              }
            }
          },
          {
            // < 768px
            breakpoint: 768,
            options: {
              chart: {
                height: 300
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '20%'
                  },
                  barSize: '65%'
                }
              },
              legend: {
                fontSize: '10px'
              }
            }
          }
        ]
      };

      this.chart = new ApexCharts(this.$refs.chart, options);
      this.chart.render();
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
</script>

<style scoped>
/* Borde redondeado en las barras */
:deep(.apexcharts-radialbar-track) path {
  stroke-linecap: round;
}
:deep(.apexcharts-radialbar) path {
  stroke-linecap: round;
}
</style>
