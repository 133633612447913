<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Estadísticas
    </template>

    <v-expansion-panel-content>
      <div class="stats-container">
        <!-- Filtro por periodo -->
        <v-row class="mb-4">
          <v-col cols="12" md="7">
            <div class="d-md-flex align-center">
              <span
                class="text-subtitle-1 font-weight-bold mr-2 filter-title"
                style="margin-top: -28px;"
              >
                Filtrar por periodo
              </span>
              <InputPeriodo
                @updateDate="getCotizaciones"
                class="filter-input"
              />
            </div>
          </v-col>
        </v-row>

        <div v-if="!loading">
          <v-row v-if="!noResults">
            <v-col
              cols="12"
              md="6"
            >
              <v-row dense>
                <v-col
                  v-for="(value, key) in formattedCotizaciones"
                  :key="key"
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <div class="coverage-card">
                    <img
                      :src="icons[key]"
                      :alt="key"
                      class="coverage-icon"
                    />
                    <!-- Texto centrado (segunda columna) -->
                    <div class="coverage-text">
                      <span class="coverage-label">{{ key }}</span>
                    </div>
                    <!-- Número alineado a la derecha (tercera columna) -->
                    <span
                      class="coverage-count"
                      :class="{ 'mobile-count': $vuetify.breakpoint.smAndDown }"
                    >
                      {{ value }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Columna derecha: la gráfica (solo se muestra en md o mayor) -->
            <v-col
              cols="12"
              md="6"
              class="d-none d-md-block"
            >
              <!-- Contenedor que limitará el ancho máximo de la gráfica -->
              <div class="chart-wrapper">
                <CotizacionesStatusGraphic :cotizaciones="cotizaciones" />
              </div>
            </v-col>
          </v-row>

          <!-- Sin resultados -->
          <div v-else>
            <v-col cols="12" md="12" class="text-center py-5">
              <span class="titulo-header">Sin resultados que mostrar</span>
            </v-col>
          </div>
        </div>

        <!-- Loading -->
        <div v-else>
          <v-row>
            <v-col>
              <v-col
                cols="12"
                class="d-flex align-center justify-center"
              >
                <v-img
                  class="responsive"
                  contain
                  max-height="200"
                  max-width="300"
                  :src="loader.createAccount"
                />
              </v-col>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import CotizacionesStatusGraphic from "@/components/commonComponents/charts/CotizacionesStatusGraphic.vue";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";
import { mainAxios } from "@/mainAxios.js";
import loginData from "@/helpers/data/data-login-template.json";

export default {
  name: "StatsCotizaciones",
  components: {
    CommonExpansionPanel,
    CotizacionesStatusGraphic,
    InputPeriodo,
  },
  data() {
    return {
      cotizaciones: {},
      loader: loginData,
      noResults: false,
      loading: false,
      icons: {
        Coche: require("@/assets/Auto.svg"),
        Celular: require("@/assets/Celular.svg"),
        Mascota: require("@/assets/Mascota.svg"),
        Médico: require("@/assets/Medicos.svg"),
      },
    };
  },
  computed: {
    formattedCotizaciones() {
      return {
        Coche: this.cotizaciones.Coche || 0,
        Celular: this.cotizaciones.Celular || 0,
        Mascota: this.cotizaciones.Mascota || 0,
        Médico: this.cotizaciones.Medico || 0,
      };
    },
  },
  methods: {
    getCotizaciones(dates) {
      this.loading = true;
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let body = { fechas: dates };

      if (String(window.location.pathname).includes("/alianza/perfil/")) {
        body.alianza = parseInt(this.$route.params.alianza_id);
      }

      mainAxios
        .post("/v1/cotizacion/tipo", body, requestConfig)
        .then(({ data }) => {
          this.cotizaciones = data;
          this.loading = false;
          this.noResults = Object.keys(this.cotizaciones).length === 0;
        })
        .catch(() => {
          this.loading = false;
          this.noResults = true;
          this.cotizaciones = {};
        });
    },
  },
};
</script>

<style scoped>
.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}

.filter-title {
  color: #4D5358;
  font-size: 1rem;
}

.filter-input {
  min-width: 50%;
}

/* 
   Nueva disposición con Grid:
   - 1a columna: icono (40px)
   - 2a columna: texto centrado
   - 3a columna: número a la derecha
*/
.coverage-card {
  display: grid;
  grid-template-columns: 40px 1fr auto;
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 12px;
  padding: 16px;
  margin: 8px;
}

.coverage-icon {
  width: 40px;
  height: 40px;
}

.coverage-text {
  text-align: center;
}

.coverage-label {
  color: #2D2F33;
  font-weight: bold;
  font-size: 1rem;
}

.coverage-count {
  text-align: right;
  color: #2D2F33;
  font-weight: bold;
  font-size: 1rem;
}

/* Aumenta el tamaño de la fuente en móviles */
/* .mobile-count {
  font-size: 1.5rem !important;
  font-weight: bold !important;
} */

/* Limita el ancho de la columna de la gráfica */
.chart-wrapper {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
