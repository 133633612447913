<template>
  <div class="d-flex flex-column" style="gap: 20px;">
    <div v-for="(doc, index) in documents" :key="index" class="d-flex align-center document-item">
     <!-- Document icon -->
      <img :src="docsIcon" class="docs-icon" />
      <div class="d-flex flex-column">
        <span class="doc-text">
          {{ doc }}
          <!-- Adds additional text for specific document indices -->
          <span v-if="(index === 2 || index === 3) && !isMobile">
            No mayor a 3 meses</span>
        </span>
        <!-- Different styling for mobile screens -->
        <span v-if="(index === 2 || index === 3) && isMobile" class="doc-custom-text">
          No mayor a 3 meses
        </span>
      </div>
      <!-- Tooltip for the last document -->
      <v-tooltip v-if="index === documents.length - 1" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="tooltip-icon">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>Te enviaremos tu documento por correo. Descárgalo, fírmalo y súbelo aquí para revisión.</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import docsIcon from "@/assets/docsIcon.png";
export default {
  data() {
    return {
      docsIcon: docsIcon, //documents icon
      windowWidth: window.innerWidth, //tracks window width for responsive behavior
    };
  },
  computed: {
    //check if the screen is mobile
    isMobile() {
      return this.windowWidth <= 960;
    },
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    //listen to window resize event
    window.addEventListener("resize", this.handleResize);
  },
  //remove event listener
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //handle window resize
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.document-item {
  gap: 12px;
}

.docs-icon {
  height: 22px;
  width: 18px;
}

.doc-text {
  font-size: 1rem;
  font-weight: 400;
  color: #303030;

  @media (max-width: 1366px) {
    font-size: 0.875rem;
  }
}

.doc-custom-text {
  font-size: 14px;
  font-weight: 400;
  color: #303030;
}

.tooltip-icon {
  color: #98A2B3;
  width:16px;
  height:16px;
}
</style>
