<template>
    <!-- Contenedor principal de la vista -->
    <v-container>
        <!-- Contenedor de la tarjeta para el contenido -->
        <v-card class="card-container">
            <v-row>
                <!-- Columna para la imagen, visible en pantallas medianas y más grandes -->
                <v-col cols="4" class="d-flex align-center">
                    <v-img class="d-none d-md-block" :src="image" cover height="100%" />
                </v-col>
                <!-- Columna para la información de la tarjeta, ajusta columnas según el tamaño de la pantalla -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="d-flex justify-center align-center"
                    :class="colPaddingClass">
                    <div class="card-info">
                        <!-- Logo de Interify -->
                        <v-img :src="interifyLogo" />
                        <!-- Imagen para vista móvil -->
                        <v-img class="image d-block d-md-none" :src="mobileImage" />

                        <!-- Sección para el ícono y texto de agradecimiento -->
                        <div class="icon-text">
                            <v-img :src="InterGif" />
                            <p>¡Enhorabuena!</p>
                        </div>
                        <!-- Texto informativo sobre la revisión de documentos -->
                        <div v-if="rol !== 'PROSPECTO'">
                            <p class="text">Tus documentos han sido validados con éxito.
                                Ahora puedes acceder a todas las funcionalidades de Interify.
                            </p>
                            <p class="text">Se te ha asignado a un asesor para apoyarte. Puedes encontrar sus datos en
                                el apartado 'Ejecutivo comercial asignado'.</p>
                            <v-btn color="primary" class="mt-4 btn-centered" v-if="rol !== 'PROSPECTO'"
                                @click="redirectToPage">¡Comencemos!</v-btn>
                        </div>
                        <p v-else class="text">Tus documentos han sido validados con éxito. <br> ¡Ponte en contacto con
                            nosotros para convertirte en Agente!.</p>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import image from '@/assets/ImageCheckingDocs.svg';
import InterGif from "@/assets/inteGif.gif";
import interifyLogo from "@/assets/interify.svg";
import mobileImage from "@/assets/checking-docs-mobile.svg";
import { mainAxios } from '@/mainAxios.js';

export default {
    name: 'DocsValidadosView',
    data() {
        return {
            image, // Imagen para vista de escritorio
            InterGif, // Imagen GIF para el ícono
            interifyLogo, // Imagen del logo
            mobileImage, // Imagen para vista móvil
            rol: JSON.parse(localStorage.getItem("roles"))[0]?.rol || "",
            agentId: JSON.parse(localStorage.getItem("id")),
        }
    },
    computed: {
        colPaddingClass() {
            // Agrega clase de padding para vista móvil
            return this.$vuetify.breakpoint.smAndDown ? 'padding-mobile' : '';
        }
    },
    methods: {
        // Redirige al usuario a la página correspondiente según su rol
        async redirectToPage() {
            if (this.rol === "PROSPECTO") {
                this.logout();
            } else {
                await this.checkHasSeenScreen(); // Verificar si el usuario ha visto la pantalla de bienvenida
                this.$emit("updateScreen", 0); // Emitir evento al padre para cambiar la pantalla
            }
        },
        logout() {
            localStorage.clear();
            this.$router.push("/");
        },
        // Verifica si el usuario ha visto la pantalla de bienvenida
        async checkHasSeenScreen() {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                }
            };
            try {
                const response = await mainAxios.put(`/v1/agente/actualizar/bienvenida/${this.agentId}`, {}, config);
                console.log("hasSeenScreen data:", response.data);

            } catch (error) {
                console.error("Error fetching hasSeenScreen data:", error);
            }
        }
    },

};
</script>

<style scoped>
/* Estilo del contenedor principal */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #001B30;
}

@media (max-width: 600px) {
    .container {
        display: contents;
    }
}

/* Estilo del contenedor de la tarjeta */
.card-container {
    background-color: #F6F5F9;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    margin: 30px;
}

@media (max-width: 600px) {
    .card-container {
        display: contents;
    }
}

/* Estilo de la sección de ícono y texto */
.icon-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.icon-text img {
    width: 63px;
    height: 58px;
}

.icon-text p {
    font-size: 20px;
    font-weight: 700;
    color: #00A5DF !important;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

/* Estilo de la sección de información de la tarjeta */
.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 14px;
    background-color: #FFFFFF;
    margin: 25px 25px 25px 5px;
    gap: 36px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .card-info {
        margin: 0;
    }
}

/* Estilo del texto */
.text {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #093E4C !important;
}

.image {
    padding: 0;
}

.text-bottom {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #00A5DF !important;
}

/* Clase de padding para vista móvil */
.padding-mobile {
    padding: 0 30px 30px 30px;
}

.btn-centered {
    display: block;
    margin: 0 auto;
    border-radius: 23px;
}
</style>