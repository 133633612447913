<template>
  <div>
    <div v-if="screen === 0">
      <!-- Contenedor general -->
      <v-container>
        <!-- Tarjeta blanca principal -->
        <v-card class="white-card pa-6" elevation="2">
          <!-- Encabezado principal con ícono -->
          <v-row align="center" class="mb-4">
            <v-col cols="auto">
              <v-img :src="groupIcon" max-width="48" />
            </v-col>
            <v-col>
              <h2 class="title">Tu camino como Asesor Digital empieza aquí.</h2>
              <p class="subheader">
                Sube tu documentación y trabajemos juntos para proteger a más
                personas.
              </p>
            </v-col>
          </v-row>

          <!-- CONTENIDO PRINCIPAL: Listado de documentos y zona de carga -->
          <v-row :class="{ 'flex-column-reverse': $vuetify.breakpoint.smAndDown }">
            <!-- LISTADO DE DOCUMENTOS REQUERIDOS (Columna Izquierda) -->
            <v-col cols="12" sm="6">
              <v-row class="document-row" v-for="document in filteredDocuments" :key="document.id"
                @click="selectDocument(document.id)" :class="{
                  'active-document': activeDocument.includes(document.id),
                }">
                <v-col cols="auto" class="document-icon d-flex align-center pa-0 ma-0">
                  <!-- Si NO hay archivo seleccionado para este documento, muestra bullet -->
                  <img v-if="!selectedFiles[document.id]" style="width: 24px" :src="bulletIcon" alt="bullet" />
                  <!-- Si SÍ hay archivo (o se forzó) para este documento, muestra ícono de vista previa -->
                  <img v-else style="width: 24px; cursor: pointer" :src="eyeClosedIcon" alt="preview-icon"
                    @click.stop="openPreview(selectedFiles[document.id])" />
                </v-col>
                <v-col cols="auto" class="document-name d-flex align-center pa-0 ma-0">
                  {{ document.name }}
                  <!-- Tooltip para "Documento de afiliación" -->
                  <v-tooltip v-if="document.name === 'Documento de afiliación'" right class="custom-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small class="ml-1 grey--text text--darken-1">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Te enviaremos tu documento por correo. Descárgalo, fírmalo y
                      súbelo aquí para revisión.
                    </span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex align-center justify-end pa-0 ma-0">
                  <!-- Ícono de semáforo -->
                  <v-icon v-if="documentStatus[document.id]" :color="statusColor(document.id)" small>
                    mdi-check-circle
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>

            <!-- ÁREA DE CARGA (Columna Derecha) -->
            <v-col cols="12" sm="6" class="d-flex justify-center align-center">
              <v-row cols="12" class="file-upload-card" :class="{
                'drag-active': isDragOver,
                'uploaded-bg': selectedFiles[activeDocumentId],
              }" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave" @drop.prevent="onFileDrop">
                <!-- Si no hay documento seleccionado -->
                <v-col v-if="!currentDocument.title" class="d-flex justify-center align-center flex-column">
                  <p class="seleciona-archivo">Selecciona un documento</p>
                  <p class="seleciona-archivo-sub">
                    PDF, PNG, JPG. Tamaño máx 5 MB
                  </p>
                </v-col>

                <!-- Si hay un documento seleccionado -->
                <v-col v-if="currentDocument.title && !selectedFiles[activeDocumentId]" cols="2"
                  class="pa-0 d-flex justify-center align-center">
                  <v-img v-if="!selectedFiles[activeDocumentId]" :src="uploadDocumentIcon" class="upload-icon" />
                </v-col>

                <v-col v-if="currentDocument.title" class="pa-5">
                  <h3 v-if="!selectedFiles[activeDocumentId]" class="documentTitle">
                    {{ currentDocument.title }}
                  </h3>

                  <!-- Si no hay archivo cargado, muestra el input -->
                  <v-card-text style="padding: 0px" v-if="!selectedFiles[activeDocumentId]">
                    <v-file-input v-model="selectedFiles[activeDocumentId]" accept=".pdf,.png,.jpg"
                      label="Da click o arrastra el documento aquí" show-size prepend-icon=""
                      :error-messages="fileErrorMessage" style="padding: 0px; cursor: pointer"
                      @change="onFileSelected" />
                  </v-card-text>
                  <p v-if="!selectedFiles[activeDocumentId]" style="font-size: 12px; margin-top: -10px">
                    PDF, PNG, JPG. Tamaño máx 5 MB
                  </p>
                  <!-- Si hay un archivo cargado y seleccionado, muestra la info -->
                  <div v-if="selectedFiles[activeDocumentId]" class="file-uploaded-container">
                    <v-img v-if="documentStatus[activeDocumentId] !== 'rechazado'" :src="fileIcon" class="upload-icon"
                      style="margin-bottom: 16px" />
                    <!-- El estado "Por validar" se mostrará solo si se ha asignado tras la subida -->
                    <div class="status-badge" v-if="documentStatus[activeDocumentId] === 'porValidar'">
                      Por validar
                    </div>
                    <div class="status-badge" v-if="documentStatus[activeDocumentId] === 'validado'"
                      style="background-color: #379e1a; color: #fff">
                      Validado
                    </div>
                    <div class="status-badge" v-if="documentStatus[activeDocumentId] === 'rechazado'"
                      style="background-color: #ea4335; color: #fff">
                      Rechazado
                    </div>
                    <!-- Mensaje de error -->
                    <p class="error-message" v-if="fileErrorMessage">
                      {{ fileErrorMessage }}
                    </p>
                    <p v-if="documentStatus[activeDocumentId] === 'rechazado'" class="error-message">
                      El archivo no cumple con los requisitos. Verifica y vuelve a
                      subirlo.
                    </p>
                    <div v-if="documentStatus[activeDocumentId] !== 'rechazado'" class="document-title">
                      {{ currentDocument.title }}
                    </div>
                    <div v-if="documentStatus[activeDocumentId] !== 'rechazado'" class="file-size-info">
                      {{ formatFileSize(selectedFiles[activeDocumentId].size) }} -
                      100% cargado
                    </div>
                    <a v-if="documentStatus[activeDocumentId] !== 'validado'" class="reload-link" href="#"
                      @click.prevent="removeFileAndResetStatus(activeDocumentId)">
                      Volver a cargar
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Leyenda de documento rechazado -->
            <v-row>
            <v-col v-if="Object.values(documentStatus).includes('rechazado')" cols="12" sm="6" class="d-flex align-center">
              <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
              <span class="text-rejected">Si tienes alguna duda, respecto al documento rechazado.
              Manda correo a <strong>contacto@interify.mx</strong> o llama al <strong>5579803073</strong> para
              continuar con tu
              proceso.</span>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-end" :class="{'offset-sm-6': !Object.values(documentStatus).includes('rechazado')}">
              <v-progress-circular v-if="isUploading" indeterminate color="primary" class="mr-4" />
                <v-btn v-if="selectedFiles[activeDocumentId] && !(
                documentStatus[activeDocumentId] === 'validado' ||
                documentStatus[activeDocumentId] === 'rechazado' ||
                documentStatus[activeDocumentId] === 'porValidar' ||
                isUploading ||
                errorTypeinFiles ||
                errorSizeinFiles
                )" class="common-botton m-0" dark color="primary" @click="uploadSingleFile">
                Subir Archivo
                </v-btn>
            </v-col>
            </v-row>
          <!-- Leyenda (semaforo de estado) -->
          <v-row class="legend-row mb-6 mt-6">
            <v-col cols="12" class="d-flex align-center">
              <div class="legend-item mr-4 d-flex align-center">
                <span class="circle circle-porvalidar mr-2"></span>
                <span>Por validar</span>
              </div>
              <div class="legend-item mr-4 d-flex align-center">
                <span class="circle circle-validado mr-2"></span>
                <span>Validado</span>
              </div>
              <div class="legend-item d-flex align-center">
                <span class="circle circle-rechazado mr-2"></span>
                <span>Rechazado</span>
              </div>
            </v-col>
          </v-row>

          <!-- BOTÓN PARA SUBIR (Subir Archivo) -->
          <v-row class="mt-4" style="
            display: flex;
            width: 100%;
            justify-content: end;
            align-items: center;
          ">
          </v-row>
        </v-card>
      </v-container>

      <!-- Diálogo de vista previa -->
      <v-dialog v-model="previewDialog" max-width="600">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <div v-if="previewFile">
              <!-- Si es imagen, usamos <v-img> -->
              <v-img v-if="previewFile.type.includes('image')" :src="previewUrl" max-height="400" />
              <!-- Caso contrario (normalmente PDF), usamos <iframe> en desktop y descarga en mobile -->
              <template v-else>
                <iframe v-if="!$vuetify.breakpoint.smAndDown" :src="previewUrl" width="100%" height="400"
                  style="border: none"></iframe>
                <v-btn v-else :href="previewUrl" target="_blank" color="primary" class="d-flex justify-center">Ver
                  archivo</v-btn>
              </template>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closePreview">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <RevisandoDocsView v-if="screen === 1" />
    <DocsValidadosView v-if="screen === 3" />
  </div>
</template>

<script>
import axios from "axios";

// Importa tus íconos
import bulletIcon from "@/assets/bulletDocumentsAlianzas.svg";
import uploadDocumentIcon from "@/assets/uploadDocumentIcon.svg";
import fileIcon from "@/assets/file_icon.svg";
import eyeClosedIcon from "@/assets/eye_closed.svg";
import groupIcon from "@/assets/Group.svg";
import RevisandoDocsView from "./RevisandoDocsView.vue";
import DocsValidadosView from "./DocsValidadosView.vue";
import { mainAxios } from '@/mainAxios.js';

export default {
  name: "UploadDocsView",
  components: {
    RevisandoDocsView,
    DocsValidadosView,
  },
  data() {
    return {
      // Iconos e imágenes
      groupIcon,
      bulletIcon,
      uploadDocumentIcon,
      fileIcon,
      eyeClosedIcon,

      // Control drag & drop
      isDragOver: false,

      // Diálogo de vista previa
      previewDialog: false,
      previewFile: null,

      // Lista de tipos de documento
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],

      // Documentos
      documentsProspects: [
        { id: 1, name: "Identificación: INE o pasaporte (vigente).", docNota: "Identificación oficial" },
        { id: 2, name: "Constancia de situación fiscal", docNota: "Constancia de situación fiscal" },
        { id: 3, name: "Comprobante de Domicilio: No mayor a 3 meses", docNota: "Comprobante de Domicilio" },
        { id: 4, name: "Carátula de Cuenta Bancaria: No mayor a 3 meses", docNota: "Caratula de estado de cuenta" },
        { id: 5, name: "Cédula de Agente de Seguros (opcional)", docNota: "Cédula de Agente" },
        { id: 6, name: "RC de Agente (opcional)", docNota: "RC de Agente" },
        { id: 7, name: "Documento de afiliación", docNota: "Documento de afiliación" },
      ],

      // Documento seleccionado (ID)
      activeDocument: [],

      // Archivos seleccionados (clave = docId)
      selectedFiles: {},

      // Mensajes de error
      fileErrorMessage: "",

      // Estado de cada documento (porValidar, validado, rechazado)
      documentStatus: {},

      // Control interno de uploading
      isUploading: false,

      // Lista de documentos subidos (desde el backend)
      prospectDocuments: [],

      // Empezamos prospectId en null
      prospectId: null,

      // Pantalla que se debe mostrar dependiendo del estado de la carga
      screen: -1,
    };
  },
  computed: {
    filteredDocuments() {
      return this.documentsProspects;
    },
    currentDocument() {
      const selectedDoc = this.filteredDocuments.find((doc) =>
        this.activeDocument.includes(doc.id)
      );
      return selectedDoc
        ? {
          title: selectedDoc.name,
          docNota: selectedDoc.docNota,
        }
        : {};
    },
    activeDocumentId() {
      return this.activeDocument[0] || null;
    },
    errorTypeinFiles() {
      return !Object.values(this.selectedFiles).every((file) =>
        this.isValidFileType(file)
      );
    },
    errorSizeinFiles() {
      return !Object.values(this.selectedFiles).every(
        (file) => file.size <= 5 * 1024 * 1024
      );
    },
    // URL para previsualizar (blob:...)
    previewUrl() {
      if (!this.previewFile) return "";
      return URL.createObjectURL(this.previewFile);
    },
  },
  mounted() {
    // Primero verificamos si hay un id en la ruta:
    if (this.$route && this.$route.params && this.$route.params.id) {
      this.prospectId = this.$route.params.id;
    } else {
      // Si no hay en la ruta, usamos el localStorage como fallback
      this.prospectId = localStorage.getItem("id") || null;
    }

    this.fetchDocuments();
  },
  methods: {
    /**
     * Llama al backend para traer la lista de documentos ya cargados.
     * Deducimos el tipo (image/pdf) según la extensión, si el backend no lo provee bien.
     */
    async fetchDocuments() {
      if (!this.prospectId) {
        console.warn("No hay prospectId disponible.");
        return;
      }
      try {
        const url = `/v1/prospecto/documentosCargados/${this.prospectId}`;
        const res = await mainAxios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        });
        this.prospectDocuments = res.data || [];

        // Mapear los documentos del backend a documentStatus y selectedFiles
        this.prospectDocuments.forEach((docBackend) => {
          let docStatus = "porValidar";
          if (docBackend.statusValid === 1) docStatus = "validado";
          else if (docBackend.statusValid === 2) docStatus = "rechazado";

          // Localiza el doc en tu lista
          const foundDoc = this.filteredDocuments.find(
            (d) => d.name === docBackend.nombre
          );
          if (!foundDoc) return;

          // Checamos la URL
          const rawUrl = docBackend.documentos[0]?.urlBucket || null;
          const isValidUrl =
            rawUrl &&
            rawUrl !== "Error: null" &&
            (rawUrl.startsWith("http://") || rawUrl.startsWith("https://"));

          // Tomamos el tipoArchivo del backend (puede ser "application/pdf", "image/png", etc.)
          let mimeType = docBackend.tipoArchivo || "application/octet-stream";

          const realFileName =
            docBackend.documentos[0]?.nombre || docBackend.nombre;

          // Si el backend dice "application/octet-stream" o no es confiable, deducimos por extensión
          if (mimeType === "application/octet-stream" || !mimeType) {
            mimeType = this.guessMimeType(realFileName) || "application/pdf";
          }

          // Asignamos a selectedFiles
          this.$set(this.documentStatus, foundDoc.id, docStatus);
          this.$set(this.selectedFiles, foundDoc.id, {
            name: realFileName,
            size: 1000,
            type: mimeType,
            url: isValidUrl ? rawUrl : null,
          });
        });
        // Llamar a validateDocumentsStatus después de actualizar documentStatus
        this.validateDocumentsStatus();
      } catch (error) {
        console.error("Error al obtener documentos:", error);
      }
    },

    // Retorna el color según el status
    statusColor(docId) {
      const status = this.documentStatus[docId];
      if (status === "validado") return "green";
      if (status === "rechazado") return "red";
      return "orange"; // porValidar
    },

    // Selecciona el documento (columna izquierda)
    selectDocument(documentId) {
      this.activeDocument = [documentId];
      this.fileErrorMessage = "";
    },

    /**
     * Cuando se elige un archivo nuevo en el <v-file-input>
     * Valida tamaño, extensión y renombra (opcional).
     */
    onFileSelected(file) {
      if (!file) {
        this.removeFile(this.activeDocumentId);
        return;
      }
      const maxFileSize = 5 * 1024 * 1024;
      const allowedExtensions = ["pdf", "png", "jpg"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      // Validaciones
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileErrorMessage =
          "Tipo de archivo no permitido. Selecciona un PDF, PNG o JPG.";
        this.$set(this.documentStatus, this.activeDocumentId, "rechazado");
        return;
      }
      if (file.size > maxFileSize) {
        this.fileErrorMessage = "El archivo supera el tamaño máximo de 5 MB.";
        this.$set(this.documentStatus, this.activeDocumentId, "rechazado");
        return;
      }
      this.fileErrorMessage = "";

      // Renombrado
      if (this.activeDocumentId) {
        const document = this.filteredDocuments.find(
          (doc) => doc.id === this.activeDocumentId
        );
        const newFileName = document
          ? `${document.name.replace(/\s+/g, "_")}.${fileExtension}`
          : file.name;
        const renamedFile = new File([file], newFileName, { type: file.type });

        this.$set(this.selectedFiles, this.activeDocumentId, renamedFile);
        // No se asigna "porValidar" hasta que se suba con éxito
      }
    },

    removeFile(documentId) {
      this.$delete(this.selectedFiles, documentId);
    },
    removeFileAndResetStatus(documentId) {
      this.removeFile(documentId);
      this.$delete(this.documentStatus, documentId);
    },

    // Valida si es un tipo de archivo permitido (PDF/PNG/JPEG)
    isValidFileType(file) {
      if (!file) return false;
      const validTypes = ["application/pdf", "image/png", "image/jpeg"];
      return validTypes.includes(file.type);
    },

    // Formatea el tamaño en KB/MB
    formatFileSize(size) {
      if (!size) return "";
      const kb = size / 1024;
      if (kb < 1024) return kb.toFixed(2) + " KB";
      const mb = kb / 1024;
      return mb.toFixed(2) + " MB";
    },

    /**
     * Abre el modal para previsualizar el archivo.
     * Si viene del backend, hacemos un GET blob para no forzar la descarga.
     */
    async openPreview(file) {
      if (!file) return;

      // Si el archivo viene del backend con una URL válida, lo traemos como blob
      if (file.url) {
        try {
          const response = await axios.get(file.url, { responseType: "blob" });
          let mimeType = response.headers["content-type"] || file.type;

          // Si el servidor regresa "application/octet-stream", deducimos el mimeType por extensión
          if (mimeType === "application/octet-stream") {
            mimeType = this.guessMimeType(file.name) || "application/pdf";
          }

          // Creamos un Blob con ese mimeType
          const blob = new Blob([response.data], { type: mimeType });
          // Armamos un File para mantener nombre y type
          const backendFile = new File([blob], file.name, { type: mimeType });

          this.previewFile = backendFile;
          this.previewDialog = true;
        } catch (err) {
          console.error("Error obteniendo el archivo:", err);
        }
      } else {
        // Si es un archivo recién cargado (no tiene .url), se usa directamente
        this.previewFile = file;
        this.previewDialog = true;
      }
    },

    // Cierra el modal y libera la URL blob
    closePreview() {
      this.previewDialog = false;
      if (this.previewFile) {
        URL.revokeObjectURL(this.previewUrl);
      }
      this.previewFile = null;
    },

    /**
     * Deducción rápida del tipo MIME según la extensión.
     * Útil cuando el backend retorna "application/octet-stream".
     */
    guessMimeType(filename) {
      if (!filename) return null;
      const ext = filename.split(".").pop().toLowerCase();
      if (ext === "pdf") return "application/pdf";
      if (ext === "png") return "image/png";
      if (ext === "jpg" || ext === "jpeg") return "image/jpeg";
      return null;
    },

    // Drag & Drop
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onFileDrop(event) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        const file = droppedFiles[0];
        this.onFileSelected(file);
      }
    },

    // Sube un solo archivo al backend
    async uploadSingleFile() {
      if (
        !this.activeDocumentId ||
        !this.selectedFiles[this.activeDocumentId]
      ) {
        console.log("No hay archivo seleccionado para subir.");
        return;
      }

      if (!this.prospectId) {
        console.warn("No hay prospectId disponible para subir archivo.");
        return;
      }

      this.isUploading = true;

      try {
        const file = this.selectedFiles[this.activeDocumentId];
        const formData = new FormData();
        formData.append("files", file);
        formData.append("nota", "null");
        formData.append("tipo", "Documento");
        formData.append("nombre", this.currentDocument.title || "");
        formData.append("tipo_documento", this.currentDocument.docNota || "");

        const url = `/v1/prospecto/documento/upload/${this.prospectId}`;
        const response = await mainAxios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        });

        // Asignar el estado "porValidar" solo después de una subida exitosa
        this.$set(this.documentStatus, this.activeDocumentId, "porValidar");

        if (response.data && response.data.documentos) {
          // Actualiza la lista prospectDocuments
          this.prospectDocuments = [
            ...this.prospectDocuments,
            ...response.data.documentos,
          ];
        }
      } catch (error) {
        console.error("Error subiendo archivo:", error);
        this.$set(this.documentStatus, this.activeDocumentId, "rechazado");
      } finally {
        this.isUploading = false;
      }
    },
    validateDocumentsStatus() {
      const allDocuments = Object.values(this.documentStatus);

      // Si no hay documentos, pantalla 0
      if (allDocuments.length === 0) {
        this.screen = 0;
        return;
      }
      // Si hay al menos un documento 'rechazado', pantalla 0
      if (allDocuments.some(status => status === 'rechazado')) {
        this.screen = 0;
        return;
      }
      // Lista de documentos opcionales
      const optionalDocs = ["Cédula de Agente de Seguros (opcional)", "RC de Agente (opcional)"];
      // Verifica si los opcionales no están cargados
      const optionalDocsNotUploaded = optionalDocs.every(docName => {
        const doc = this.filteredDocuments.find(d => d.name === docName);
        return doc && !this.selectedFiles[doc.id];
      });
      // Filtra solo los documentos obligatorios
      const requiredDocs = this.filteredDocuments.filter(doc => !optionalDocs.includes(doc.name));
      // Verifica cuántos documentos obligatorios han sido subidos (con estado válido en documentStatus)
      const uploadedRequiredDocs = requiredDocs.filter(doc => this.documentStatus[doc.id]).length;
      // Si hay documentos obligatorios faltantes, pantalla 0
      if (uploadedRequiredDocs < requiredDocs.length) {
        this.screen = 0;
        return;
      }
      // Si solo faltan documentos opcionales, pantalla 1
      if (optionalDocsNotUploaded) {
        this.screen = 1;
        return;
      }
      // Si todos los documentos están 'validado', pantalla 3
      if (allDocuments.every(status => status === 'validado')) {
        this.screen = 3;
        return;
      }
      // Si al menos un documento está 'porValidar', pantalla 1
      if (allDocuments.some(status => status === 'porValidar')) {
        this.screen = 1;
        return;
      }
      // En cualquier otro caso, pantalla 0
      this.screen = 0;
    }

  },
};
</script>

<style scoped>
.white-card {
  background-color: #fff;
  border-radius: 8px;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #00a7df;
  margin-bottom: 4px;
}

.subheader {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #4f4f4f;
  margin-bottom: 0;
}

/* Ajuste para el row de documentos */
.document-row {
  margin: 5px !important;
  padding: 4px 0 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-document {
  background-color: #f0f0f0;
  border-left: 4px solid #00a7df;
  padding-left: 12px;
  transition: background-color 0.3s;
}

.document-name {
  font-family: "Source Sans 3", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .document-name {
    width: 30px;
    font-size: 12px;
  }
}

.document-icon img {
  margin-right: 8px;
}

.file-upload-card {
  padding: 1px;
  /* Reduce un poco el padding */
  /* margin-right: 30px;           En lugar de margin-left */
  /* margin-bottom: 20px; */
  border-radius: 10px;
  border: 2px dashed #00000040;
  /* min-height: 10px; */
  /* max-width: 520px; */
  width: 100%;
}

.file-upload-card.drag-active {
  border: 2px dashed #1976d2;
  background-color: #e3f2fd;
}

.uploaded-bg {
  background-color: #f6f5f9;
}

.upload-icon {
  max-width: 46px;
  max-height: 46px;
}

.seleciona-archivo {
  font-family: "Source Sans 3", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #00000040;
  margin: 0;
}

.seleciona-archivo-sub {
  font-family: "Source Sans 3", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #00000040;
  margin: 0;
}

.error-message {
  color: red;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: center;
}

.common-botton {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 600;
  text-transform: none;
}

.legend-item {
  font-family: "Source Sans 3", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.circle-porvalidar {
  background-color: #fbbc05;
}

.circle-validado {
  background-color: #34a853;
}

.circle-rechazado {
  background-color: #ea4335;
}

.file-uploaded-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.status-badge {
  background-color: #fbbc05;
  color: #000;
  font-weight: bold;
  border-radius: 10px;
  padding: 4px 12px;
  margin-bottom: 16px;
  width: 248px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.document-title {
  font-weight: 700;
  color: #4d5358;
  margin-bottom: 8px;
  font-size: 16px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.file-size-info {
  color: #666;
  margin-bottom: 17px;
  font-size: 12px;
  font-family: "Source Sans 3", sans-serif;
}

.reload-link {
  color: #00a7df;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.reload-link:hover {
  text-decoration: underline;
}

.text-rejected {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
</style>
