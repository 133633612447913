<template>
  <div class="carousel-container">
    <v-carousel
      style="height: 180px"
      :cycle="false"
      ref="carousel"
      hide-delimiters
      class="carousel-content"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn large v-bind="attrs" v-on="on" icon text>
          <v-icon color="#00a7e4" medium>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn large v-bind="attrs" v-on="on" icon text>
          <v-icon color="#00a7e4" medium>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-carousel-item
          v-for="group in groupedItems"
          :key="group[0].texto"
          style="padding: 0 20px;"
        >
          <v-row no-gutters justify="center">
            <v-col
              v-for="item in group"
              :key="item.texto"
              cols="12"
              md="4"
              class="p-16"
              @click="goCotizacion(item.src, item.texto)"
            >
              <v-card
                style="border-radius: 26px!important; background: rgba(246, 245, 249, 1);"
                :class="['mx-auto', 'card', 'rounded-card', 'elevation-4']"
                flat
              >
                <v-card-text
                  class="px-4 text-center"
                  style="position:relative; min-height: 100px; display: flex; flex-direction: column; justify-content: center;"
                >
                  <img
                    :src="item.icono"
                    alt=""
                    height="50"
                    style="position:absolute; top:-18px; left:50%; transform:translate(-50%, 0);"
                  />
                  <h6 class="cardTitle" :class="{ 'white--text': hover }">
                    {{ item.texto }}
                  </h6>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </template>

      <template v-if="!$vuetify.breakpoint.mdAndUp">
        <v-carousel-item
          v-for="item in items"
          :key="item.texto"
          style="padding: 0 20px;"
        >
          <v-row no-gutters justify="center">
            <v-col cols="12" class="p-16">
              <v-card
                style="border-radius: 26px!important; background: rgba(246, 245, 249, 1);box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
                :class="['mx-auto', 'card', 'rounded-card', 'elevation-4']"
                flat
                @click="goCotizacion(item.src, item.texto)"
              >
                <v-card-text
                  class="px-4 text-center"
                  style="position:relative; height: 108px; display: flex; flex-direction: column; justify-content: center; align-items: center;"
                >
                  <img
                    :src="item.icono"
                    alt=""
                    height="50"
                    style="position:absolute; top:-18px; left:50%; transform:translate(-50%, 0);"
                  />
                  <h6 :class="{ 'white--text': hover }" class="cardTitle">
                    {{ item.texto }}
                  </h6>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "NewCommonCarousel",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      iconColor: "#00A7E4",
    };
  },
  computed: {
    groupedItems() {
      const grouped = [];
      for (let i = 0; i < this.items.length; i += 3) {
        grouped.push(this.items.slice(i, i + 3));
      }
      return grouped;
    },
  },
  methods: {
    next() {
      if (this.$refs.carousel) {
        this.$refs.carousel.next();
      }
    },
    prev() {
      this.$refs.carousel.prev();
    },
    toggleHover() {
      this.hover = !this.hover;
    },
    goCotizacion(src, texto) {
      window.dataLayer.push({
        event: "ITFY_hub_cotizaciones",
        seguro: texto,
        userID: localStorage.agenteMail,
      });
      this.$router.push(src);
    },
  },
  mounted() {
    setInterval(() => {
      this.next();
    }, 10000);
  },
};
</script>

<style>
.cardTitle {
  color: #00a7e4;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: center;
  color: rgba(77, 83, 88, 1);
}

.carousel-container {
  position: relative;
  cursor: pointer;
}

.carousel-content {
  width: 80%;
  margin: 0 auto;
}

.v-carousel__controls__item {
  color: black !important;
  font-size: 10px;
}

.rounded-card {
  border-radius: 20px !important;
}

.card {
  background: white;
  transition: background 0.3s, color 0.3s;
}

.card:hover {
  background: #00a7e4 !important;
}

.card:hover .v-card__text h6,
.card:hover .v-card__text .v-icon {
  color: white !important;
}

.v-window__prev,
.v-window__next {
  background: transparent !important;
  border-radius: 50%;
  position: absolute;
  margin: -20px -12px !important;

  z-index: 1;
}

.p-16 {
  padding: 16px !important;
}
</style>
