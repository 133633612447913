<template>
  
      <common-card style="padding: 12px;">
        <v-container>
          <v-row>
            <v-col>
              <v-row style="padding: 15px 20px 0px 20px;">
                <v-col cols="12" md="6">
                  <h1>
                    Prospectos
                  </h1>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-expansion-panels elevation="0" style="padding-block: 30px; border-radius: 20px">
            <v-expansion-panel>
              <v-expansion-panel-header style="padding: 35px;">
                  <h3 style="color:#42352E;">
                    Búsqueda detallada
                  </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="search"
                          label="Buscar"
                          hint="Buscar un agente por nombre, correo o RFC"
                          class="mx-4 my-2"
                          outlined
                          dense
                          color="primary"
                          background-color="white"
                          @keyup.enter="getFiltroProspecto"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" style="text-align: end;">
                      <v-btn class="common-botton" dark :loading="loading" @click="getFiltroProspecto">
                        <span>Filtrar</span>
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div style="padding: 0px 40px 0px 40px;">
            <hr>
          </div>
          <v-row>
            <v-col cols="12">
              <tabla-alianza
              :loading="loading"
              :items="prospectos"
              :editItem="editItem"
              :statusMap="statusMap"
              :headers="headers"
            />
            </v-col>
            <!-- <v-col cols="12">
              <v-data-table
                style=""
                :headers="agentesTable"
                :items="prospectos"
                item-key="name"
                class="elevation-0"
                :loading="loading"
                loading-text="Cargando..."
                no-data-text="Para ver elementos, primero realiza una búsqueda"

              >
                <template v-slot:top>
                  <v-row style="padding: 15px;">
                    <v-col cols="12" md="8">
                      <h2>Resultados de la búsqueda</h2>
                    </v-col>
                    <v-col cols="12" md='4' style="text-align: end;">
                    <download-csv :data="prospectos" name="prospectos_reporte.csv">
                      <v-btn color="transparent" elevation="0" small>
                        <span style="color:#0070C0;">Descargar</span>
                        <v-icon small right class="mr-2" color="#0070C0">mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </download-csv>
                    </v-col>
                  </v-row>
                </template> 
                <template v-slot:item.status="{ item }">
                    <v-chip
                      class="ma-2"
                      color="green"
                      text-color="white"
                      dark
                    >
                     {{ getStatus(item.status) }}
                    </v-chip>
                </template>

                <template v-slot:item.edit="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-col> -->
          </v-row>
        </v-container>
      </common-card>
</template>

<script>
//import RfcFacil from 'rfc-facil';
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from '@/components/commonComponents/CommonCard.vue';
import TablaAlianza from '~/views/pages/administrador/alianzas/perfil/components/TablaAlianza.vue';
import {statusMap, headers} from './utils/constants.js';


export default {
  components: {
    CommonCard,
    TablaAlianza
  },
  data() {
    return {
      moment,
      prospectos: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      agentesTable: [
        { text: "ID", value: "id" },
        { text: "Estatus", value: "status" },
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombreCompleto",
        },
        { text: "Telefono", value: "telefono" },
        { text: "Correo Electronico", value: "correo" },
        { text: "Fecha de Registro", value: "fechaRegistro" },
        { text: "Ver Agente", value: "edit" },
      ],
      report: [],
      statusMap: statusMap,
      headers: headers
    };
  },


  methods: {
    getAgent() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/prospecto/getAll`,config).then((response) => {
        this.prospectos = []
        response.data.map(element => {
          //if status is "converted" then don't show it
          if(element.status !== "3"){
            this.prospectos.push({
            id: element.id ?? null,
            nombre: element.nombre ?? 'No disponible',
            nombreCompleto: element.nombre + " " + element.apellidoPaterno + ' ' + element.apellidoMaterno,
            apellidoMaterno: element.apellidoMaterno ?? 'No disponible',
            apellidoPaterno: element.apellidoPaterno ?? 'No disponible',
            correo: element.correo ?? 'No disponible',
            fechaRegistro:  element.fechaCreacion ? element.fechaCreacion: 'No disponible',
            rfc: element.rfc ?? 'No disponible',
            status: element.status ?? 'No disponible',
            telefono: element.telefono ?? null,
            celular: element.celular ?? null,
          })
          }
        })
        this.loading = false;
      })
    },

    getFiltroProspecto(){
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const body = {
        filtroBusqueda: this.search,
      };
      mainAxios
        .post(`/v1/prospecto/list/filtros`, body, config)
        .then((response) => {
          this.prospectos = []
          response.data.map(element => {
            if(element.status !== "3"){
            this.prospectos.push({
            id: element.id ?? null,
            nombre: element.nombre ?? 'No disponible',
            nombreCompleto: element.nombre + " " + element.apellidoPaterno + ' ' + element.apellidoMaterno,
            apellidoMaterno: element.apellidoMaterno ?? 'No disponible',
            apellidoPaterno: element.apellidoPaterno ?? 'No disponible',
            correo: element.correo ?? 'No disponible',
            fechaRegistro:  element.fechaCreacion ? element.fechaCreacion: 'No disponible',
            rfc: element.rfc ?? 'No disponible',
            status: element.status ?? 'No disponible',
            telefono: element.telefono ?? null,
            celular: element.celular ?? null,

          })
        }
        })
        this.loading = false;
        });
    },
    editItem(id) {
      this.$router.push(`/perfil/prospecto/${id}`);
    },
    getStatus(status){
      switch(status){
        case '1': 
          return 'Nuevo'
        case '2': 
          return 'Prospección'
        case '3': 
          return 'Convertido'
        case '4': 
          return 'Rechazado'
        case '5': 
          return 'Declinado'
      }
    }
  },
  mounted(){
    this.getAgent()
  }
};
</script>
<style scoped>
.theme--light.v-data-table {
    background-color: #f2fafc;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 20px;
}

.v-expansion-panel{
    border-radius: 20px;
}
.v-expansion-panels{
    z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active{
    border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #F6F5F9;
    color: rgba(0, 0, 0, 0.87);
}
</style>
