<template>
  <div ref="chart"></div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: "EmisionesStatusGraphic",
  props: {
    emisiones: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    emisiones: {
      deep: true,
      handler() {
        // Verificamos que exista la instancia de la gráfica y la data
        if (this.chart && this.emisiones["Emisiones-stats"]) {
          // 1. Obtenemos todas las llaves
          const allKeys = Object.keys(this.emisiones["Emisiones-stats"]);
          // 2. Filtramos las que tengan valor != 0
          const filteredKeys = allKeys.filter(k => this.emisiones["Emisiones-stats"][k] !== 0);
          // 3. Obtenemos la serie con esos valores
          const filteredSeries = filteredKeys.map(k => this.emisiones["Emisiones-stats"][k]);

          // 4. Actualizamos la gráfica con los datos filtrados
          this.chart.updateOptions({ labels: filteredKeys });
          this.chart.updateSeries(filteredSeries);
        }
      }
    }
  },
  methods: {
    renderChart() {
      // Si no existe la propiedad "Emisiones-stats", no hacemos nada
      if (!this.emisiones["Emisiones-stats"]) return;
      
      // 1. Obtenemos todas las llaves
      const allKeys = Object.keys(this.emisiones["Emisiones-stats"]);
      // 2. Filtramos las que tengan valor != 0
      const filteredKeys = allKeys.filter(k => this.emisiones["Emisiones-stats"][k] !== 0);
      // 3. Obtenemos la serie con esos valores
      const filteredSeries = filteredKeys.map(k => this.emisiones["Emisiones-stats"][k]);

      const options = {
        series: filteredSeries,
        chart: {
          height: 350,
          width: '100%',
          type: 'radialBar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 350,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent'
            },
            track: {
              show: true,
              background: '#f2f2f2',
              opacity: 0.5,
              strokeWidth: '95%',
              margin: 5
            },
            dataLabels: {
              name: { show: false },
              value: { show: false },
              total: {
                show: true,
                label: 'Total',
                fontSize: '14px',
                formatter: function(w) {
                  return w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                }
              }
            },
            cornerRadius: '55%',
            strokeLinecap: 'round',
            trackSize: '95%',
            barSize: '85%' // Ajustaremos en breakpoints
          }
        },
        stroke: {
          lineCap: 'round',
          width: 4
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            stops: [0, 100]
          }
        },
        labels: filteredKeys,
        legend: {
          show: true,
          floating: false,
          position: 'bottom',
          horizontalAlign: 'center',
          offsetY: 0,
          fontSize: '13px',
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 8,
            radius: 12
          },
          formatter: function(seriesName) {
            return seriesName;
          },
          itemMargin: {
            vertical: 5
          }
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              chart: {
                height: 320
              },
              plotOptions: {
                radialBar: {
                  hollow: { size: '28%' },
                  barSize: '80%'
                }
              },
              legend: {
                fontSize: '12px'
              }
            }
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 300
              },
              legend: {
                position: 'bottom'
              },
              plotOptions: {
                radialBar: {
                  hollow: { size: '25%' },
                  barSize: '75%'
                }
              }
            }
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 250
              },
              legend: {
                position: 'bottom',
                fontSize: '12px'
              },
              plotOptions: {
                radialBar: {
                  hollow: { size: '22%' },
                  barSize: '70%'
                }
              }
            }
          }
        ]
      };

      this.chart = new ApexCharts(this.$refs.chart, options);
      this.chart.render();
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
</script>

<style scoped>
:deep(.apexcharts-radialbar-track) path {
  stroke-linecap: round;
}
:deep(.apexcharts-radialbar) path {
  stroke-linecap: round;
}
</style>
