<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Estadísticas
    </template>
    <v-expansion-panel-content>
      <div class="stats-container">
        <v-row class="mb-4">
          <v-col cols="12" md="7">
            <div class="d-md-flex align-center">
              <span class="text-subtitle-1 font-weight-bold mr-2 filter-title">
                Filtrar por periodo
              </span>
              <InputPeriodo @updateDate="getTodosLosClientes" class="filter-input" />
            </div>
          </v-col>
        </v-row>

        <div v-if="!loading">
          <v-row v-if="!noResults">
            <!-- Columna izquierda: tarjetas dinámicas -->
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col
                  v-for="(item, index) in filteredClientes"
                  :key="index"
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <div class="coverage-card">
                    <div class="coverage-text">
                      <span class="coverage-label">{{ item.name }}</span>
                      <span class="coverage-count">{{ item.value }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Columna derecha: gráfica (se oculta en pantallas pequeñas) -->
            <v-col cols="12" md="6" class="d-none d-md-block">
              <ClientesStatusGraphic :clientes="todosLosClientes" />
            </v-col>
          </v-row>

          <!-- Sin resultados -->
          <div v-else>
            <v-col cols="12" md="12" class="text-center py-5">
              <span class="titulo-header">Sin resultados que mostrar</span>
            </v-col>
          </div>
        </div>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ClientesStatusGraphic from "@/components/commonComponents/charts/ClientesStatus.graphic.vue";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    ClientesStatusGraphic,
    CommonExpansionPanel,
    InputPeriodo,
  },

  data() {
    return {
      todosLosClientes: {},
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      noResults: false,
      loading: false,
      statusArray: ["Activo", "Baja", "Inactivo", "Nuevo"],
    };
  },

  computed: {
    filteredClientes() {
      if (!this.todosLosClientes.status) return [];
      return Object.entries(this.todosLosClientes.status)
        .filter(([, value]) => value > 0)
        .map(([name, value]) => ({ name, value }));
    }
  },

  methods: {
    getTodosLosClientes(dates) {
      this.loading = true;
      let body = { fechas: dates };
      if (String(window.location.pathname).includes("/alianza/perfil/")) {
        body.alianza = parseInt(this.$route.params.alianza_id);
      }
      mainAxios
        .post("v1/cliente/dashboard/data", body, this.requestConfig)
        .then(({ data }) => {
          this.todosLosClientes = data;
          this.loading = false;
          this.checkIfHasResults();
        });
    },
    checkIfHasResults() {
      this.noResults = this.filteredClientes.length === 0;
    }
  },
};
</script>

<style scoped>
.stats-container {
  padding: 30px;
}

.filter-title {
  color: #4D5358;
  font-size: 1rem;
  margin-top: -31px;
}

.filter-input {
  min-width: 50%;
}

.coverage-card {
  display: flex;
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 12px;
  padding: 16px;
  box-shadow: none;
  cursor: default;
  margin: 8px;
}

.coverage-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coverage-label,
.coverage-count {
  color: #2D2F33;
  font-weight: bold;
  font-size: 1rem;
}
</style>
