<template>
  <commond-form style="margin-right: 20px;" :request="createAccount" :run="run" :onlyValidations="true" @cancel="run = false">
    <template v-slot:data>
      <v-container>
        <v-row>
          <v-col cols="12" justify="center" align="center">
            <v-img max-height="36" max-width="142" :src="LogoInter"></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" align="center">
            <v-text class="text-center" style="font-family: 'Montserrat'; font-size: 16px; font-weight: 400;">
              Crea una cuenta y conviértete en un Asesor Digital interify
            </v-text>
          </v-col>
        </v-row>

        <v-row justify="center" class="flex-wrap-reverse-md">
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-text-field v-model="createAccount.name" label="Nombre*" required outlined dense
              :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-text-field v-model="createAccount.surname" label="Primer Apellido*" outlined dense
              :rules="requiredRule"></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" class="flex-wrap-reverse-md">
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-text-field v-model="createAccount.lastname" label="Segundo Apellido*" outlined dense
              :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-autocomplete v-model="createAccount.sex" :items="gender" item-text="dGenero" item-value="cGenero"
              label="Genero*" :rules="requiredRule" outlined dense></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" class="flex-wrap-reverse-md">
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-text-field v-model="createAccount.birthday" type="date" label="Fecha de nacimiento*" outlined dense
              :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-autocomplete v-model="createAccount.placeBirth" :items="states" item-text="dEstado" item-value="cEstado"
              label="Estado de nacimiento*" :rules="requiredRule" outlined dense></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" class="flex-wrap-reverse-md">
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-text-field v-model="contactInformation.tel" type="tel" label="Número de celular*" outlined maxlength="10"
              dense :rules="phoneRules"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0 pr-0">
            <v-col cols="12">
              <v-row>
          <v-col cols="12" class="pa-0">
            <v-row v-for="(element, index) in contactInformation.emails" :key="index">
              <v-col cols="12">
                <CommonEmailAutocomplete @emailUpdate="(e) => (element.correo = e)" :isDense="true">
                </CommonEmailAutocomplete>
              </v-col>
            </v-row>
          </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pb-10 pt-0" cols="12" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-start'" align="start">
            <v-text>Al hacer clic en siguiente estás aceptando el
              <a class="tyc" target="_blank" href=" https://www.inter.mx/aviso-de-privacidad">Aviso de privacidad</a>
              y
              <a class="tyc" target="_blank" href="https://www.inter.mx/terminos-condiciones">Términos y condiciones</a>
            </v-text>
          </v-col>
        </v-row>


        <v-col cols="12" v-if="emailsRepeat.length > 0">
          <v-alert type="warning" dense dismissible>
            Los siguientes correos ya se encuentran registrados en la
            plataforma
            <ul v-for="(element, index) in emailsRepeat" :key="index">
              <li>{{ element }}</li>
            </ul>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="phoneRepeat">
          <v-alert type="warning" dense dismissible>
            El siguiente número de celular ya se encuentra registrado
            <ul>
              <li>{{ telRepeat }}</li>
            </ul>
          </v-alert>
        </v-col>
        <v-col cols="12" justify="center" class="d-flex justify-center">
          <v-btn color="#00A7E4" dark class="rounded-xl mb-1" width="150px" style="margin-top: -10px" @click="nextStep"
            :disabled="!allFieldsValidated()">
            Siguiente
          </v-btn>
        </v-col>

        <v-row justify="center" align="center">
          <v-text>
            ¿Ya tienes una cuenta?
            <a style="font-weight: 600; color: #039ecc" href="/">
              Inicia sesión
            </a>
          </v-text>
        </v-row>

      </v-container>
    </template>
  </commond-form>
</template>

<script>
import CommondForm from "../../../components/commonComponents/CommondForm.vue";
import { mainAxios } from "../../../mainAxios.js";
import CommonEmailAutocomplete from "../../../components/commonComponents/CommonEmailAutocomplete.vue";
import LogoInter from "@/assets/interify.svg";
import RfcFacil from "rfc-facil";
export default {
  name: "DatosPersonalesProspectos",
  components: { CommondForm, CommonEmailAutocomplete },
  props: {
    contactInformation: Object,
    createAccount: Object,
    emailsRepeat: Array,
    phoneRepeat: String,
    telRepeat: String,
  },
  data() {
    return {
      LogoInter,
      states: [],
      gender: [
        { cGenero: 1, dGenero: "Masculino" },
        { cGenero: 2, dGenero: "Femenino" },
      ],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      phoneRules: [
        (v) => !!v || "El número de teléfono es requerido",
        (v) => /^\d{10}$/.test(v) || "Debe ser un número de teléfono válido",
        (v) =>
          !/^(.)\1{9}$/.test(v) ||
          "No se permiten secuencias de dígitos repetidos",
        (v) =>
          !["1111111111", "9999999999", "0000000000"].includes(v) ||
          "El número de teléfono no es válido",
      ],
      emailRules: [
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !!value && value.length
            ? pattern.test(value) || "Dirección de correo incorrecta"
            : true;
        },
      ],
    };
  },
  methods: {
    getStates() {
      const config = {
        headers: {
          Authorization: "Bearer 7af112f1-963e-4c4b-bca9-50e45fccd00a",
        },
      };
      mainAxios.get("/v1/catalogo/estado", config).then((response) => {
        this.states = response.data;
      });
    },
    allFieldsValidated() {
      const fieldsToCheck = [
        this.createAccount.name,
        this.createAccount.surname,
        this.createAccount.lastname,
        this.createAccount.birthday,
        this.createAccount.placeBirth,
        this.createAccount.sex,
        this.contactInformation.tel,
        this.contactInformation.correo,
        // this.createAccount.generatedRfc,
        // this.createAccount.homoclave,
      ];

      const validations = [
        this.requiredRule[0](this.createAccount.name),
        this.requiredRule[0](this.createAccount.surname),
        this.requiredRule[0](this.createAccount.lastname),
        this.requiredRule[0](this.createAccount.birthday),
        this.requiredRule[0](this.createAccount.placeBirth),
        this.requiredRule[0](this.createAccount.sex),
        this.phoneRules[(0, 1)](this.contactInformation.tel),
        this.emailRules[0](this.contactInformation.correo),
        // this.requiredRule[0](this.createAccount.generatedRfc),
        // this.homoclaveRule[0](this.createAccount.homoclave),
      ];

      return (
        fieldsToCheck.every((field) => !!field) &&
        validations.every((validation) => validation === true)
      );
    },
    nextStep() {
      this.$emit("isNextStep", true);
      // window.dataLayer.push({
      //   event: "ITFY_datos_personales",
      //   fecha_nacimiento: this.createAccount.birthday
      //     .split("-")
      //     .reverse()
      //     .join("/"),
      //   sexo: this.createAccount.sex == 1 ? "Femenino" : "Masculino",
      //   boton_contacto: "Siguiente",
      // });
    },
    generateRfc() {
      if (
        this.createAccount.name &&
        this.createAccount.surname &&
        this.createAccount.lastname &&
        this.createAccount.birthday
      ) {
        const arregloNacimiento = this.createAccount.birthday
          .split("-")
          .map((e) => Number(e));

        const rfc = RfcFacil.forNaturalPerson({
          name: this.createAccount.name,
          firstLastName: this.createAccount.surname,
          secondLastName: this.createAccount.lastname,
          day: arregloNacimiento[2],
          month: arregloNacimiento[1],
          year: arregloNacimiento[0],
        });
        this.createAccount.generatedRfc = rfc.substring(0, rfc.length - 3);
        this.createAccount.homoclave = rfc.substring(10, rfc.length);
      }
    },
  },
  mounted() {
    this.getStates();
    // window.dataLayer.push({
    //   event: "ITFY_datos_personales_v",
    //   no_cotizacion: undefined,
    //   userID: undefined,
    //   correo: undefined,
    //   telefono: undefined,
    // });
  },
  computed: {},
  watch: {
    "createAccount.name": "generateRfc",
    "createAccount.surname": "generateRfc",
    "createAccount.lastname": "generateRfc",
    "createAccount.birthday": "generateRfc",
    "contactInformation.emails": {
      handler(newValue) {
        const favoriteEmail = newValue.find((emailObj) => emailObj.favorito);
        this.contactInformation.correo = favoriteEmail
          ? favoriteEmail.correo
          : "";
        console.log("Nuevo valor de rarisimo:", newValue);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.row-container {
  max-width: 100%;
  margin: auto;
}

v-text {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
}

.tyc {
  font-family: "Montserrat";
  font-weight: 600;
}
</style>
