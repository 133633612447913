import { render, staticRenderFns } from "./InicioProspectos.vue?vue&type=template&id=58799e44&scoped=true"
import script from "./InicioProspectos.vue?vue&type=script&lang=js"
export * from "./InicioProspectos.vue?vue&type=script&lang=js"
import style0 from "./InicioProspectos.vue?vue&type=style&index=0&id=58799e44&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58799e44",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VCol,VImg,VRow})
