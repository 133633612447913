<template>
  <v-container style=" max-width: 100%;">
    <common-card
      class="mb-5 cardContainer"
      style="min-height: 21rem; padding: 32px"
    >
      <v-container
        class="titleContainer"
        style="width:fit-content; margin:0;  flex:3; width: 100%; padding:0"
      >
        <img
          src="https://segurify-data.s3.us-east-1.amazonaws.com/componentes_ux_ui/solicitudes_icon.svg"
          alt=""
          style="width:68px; height:68px"
        />
        <v-container class="cardDescription">
          <h2>Solicitudes</h2>
          <span>
            Realiza tu solicitud completa llenando todos los datos para hacer
            cotizaciones, emitir, endosar, entre muchas otras cosas.
          </span>
        </v-container>
      </v-container>

      <v-card
        style="background-color: rgba(246, 245, 249, 1);border-radius: 10px; padding: 26px; flex:2; width: 100%;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
        v-if="canCreateSolicitudes"
        @click="
          $router.push('/solicitud-crear/10');
          solicitudesDataLayer('Crear Solicitudes');
        "
      >
        <v-row class="cardConatinerSolicitud">
          <img src="https://segurify-data.s3.us-east-1.amazonaws.com/componentes_ux_ui/crear_solicitud_icon.svg" alt="" />

          <v-col
            class=" text-center cardDescriptionSolicitud"
            style="cursor: pointer; width: 100%;"
          >
            <h4 class="hidden-sm-and-down" style="text-transform:uppercase;">
              Crear solicitud
            </h4>
            <h6 class="hidden-md-and-up " style="text-transform:uppercase;">
              Crear solicitud
            </h6>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        style="background-color: rgba(246, 245, 249, 1);border-radius: 10px; padding: 26px;  flex:2; width: 100%; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
        @click="
          $router.push('/main-hub/1');
          solicitudesDataLayer('Mis Solicitudes');
        "
      >
        <v-row class="cardConatinerSolicitud">
          <img src="https://segurify-data.s3.us-east-1.amazonaws.com/componentes_ux_ui/mis_solicitudes_icon.svg" alt="" />

          <v-container
            class=" align-center text-center cardDescriptionSolicitud"
            style="cursor: pointer; width: 100%;"
          >
            <h4 class="hidden-sm-and-down" style="text-transform:uppercase;">
              Mis solicitudes
            </h4>
            <h6 class="hidden-md-and-up" style="text-transform:uppercase;">
              Mis solicitudes
            </h6>
          </v-container>
        </v-row>
      </v-card>
    </common-card>
  </v-container>
</template>

<script>
const moment = require("moment");
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
import CommonCard from "../../components/commonComponents/CommonCard.vue";
import dashboardJson from "../../helpers/data/data-dashboard-template.json";

export default {
  components: { CommonCard },
  data() {
    return {
      userID: localStorage.agenteMail,
      itemsCarousel: [
        {
          icono: dashboardJson.iconPlus,
          texto: "MEDICO SEGURO",
          src: "/cotizacion/medico",
        },
        {
          icono: dashboardJson.iconCar,
          texto: "COCHE SEGURO",
          src: "/cotizacion/vehiculos",
        },
        {
          icono: dashboardJson.iconPet,
          texto: "MASCOTA SEGURA",
          src: "/cotizacion/mascota",
        },
        // {
        //   icono: dashboardJson.iconPhone,
        //   texto: "CELULAR SEGURO",
        //   src: "/cotizacion/celular",
        // },
      ],
      banner: 0,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      banners: [
        //New banners
        {
          id: 1,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_AIG.jpg",
        },
        {
          id: 2,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_GNP.jpg",
        },
        {
          id: 3,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_HDI.jpg",
        },
        {
          id: 4,
          url:
            "segurify_banner_portalagentes_2450x400_ene2023_060123_qualitas.jpg",
        },

        //{ id: 1, url: 'segurify_banner_portalagentes_2450x400_190522_gnp2.jpg' },
        //{ id: 2, url: 'segurify_banner_portalagentes_2450x400_190522_aig.jpg' },
        //{ id: 3, url: 'segurify_banner_portalagentes_2450x400_190522_qualitas.jpg' },
        // { id: 1, url: 'SEGURIFY_banner_2449x500_PortalAgentes_031221.jpeg' },
        // { id: 2, url: 'SEGURIFY_banner_2449x500_BuenFin_051121_Qualitas (2).jpeg' },
        //{id: 1, url: 'SEGURIFY_banner_2449x500_BlackFriday_171121_GNP.png'},
        // {id: 1, url: 'SEGURIFY_banner_2449x500_BuenFin_091121_GNP.jpg'},
        //{id: 4, url: 'SEGURIFY_PortalAgentes_001_bienvenida.jpg'},
        //{id: 5, url: 'SEGURIFY_PortalAgentes_002_proceso.jpg'},
        //{id: 6, url: 'SEGURIFY_PortalAgentes_003_control.jpg'},
        // {id: 7, url: 'SEGURIFY_promo_AIG_05:07:21.jpg' },
      ],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      items: [
        {
          title: "Cotiza",
          text: "Cotiza con nuestras aseguradoras",
          route: "cotiza-hub",
        },
        {
          title: "Mis Emisiones",
          text:
            "Aquí guardaremos el detalle de tus cotizaciones para que lleves un mejor control de tus ventas de auto.",
          route: "mis-emisiones",
        },
        {
          title: "Mis Cotizaciones",
          text: "¿Deseas retomar una cotización? Desde aquí puedes hacerlo.",
          route: "mis-cotizaciones",
        },
        // {
        //   title: "Solicitudes",
        //   text: "¿Tienes alguna solicitud para nuestro equipo de operaciones? Desde aquí puedes hacerlo.",
        //   route:"solicitudes"
        // },
      ],
      transparent: "rgba(255, 255, 255, 0)",
      value: [423, 446, 675, 510, 590, 610, 760],
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      year: moment().year(),
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
    };
  },
  methods: {
    canCreateSolicitudes() {
      let area_negocio_id = localStorage.getItem("area_negocio_id");
      if (
        area_negocio_id == "9" ||
        area_negocio_id == "10" ||
        this.rol == "ADMIN"
      )
        return true;
      else return false;
    },
    verCotizaciones() {
      this.$router.push("/cotizacion-hub-seguros");
      // this.$router.push('/cotiza-hub')
    },
    verPolizas() {
      this.$router.push("/mis-emisiones");
    },
    verSolicitudes() {
      this.$router.push("/solicitudes");
    },
    verComisiones() {
      this.$router.push("/lista-comisiones");
    },
    verCuotas() {
      this.$router.push("/cuotas-hub");
    },
    verClientes() {
      this.$router.push("/lista-clientes");
    },
    verLandingCelular() {
      this.$router.push("/landing-celular");
    },
    solicitudesDataLayer(text) {
      window.dataLayer.push({
        event: "ITFY_hub_solicitudes",
        opciones: text,
        userID: localStorage.getItem("agenteMail"),
      });
    },
  },
  computed: {
    mostrarCardComision() {
      if (
        this.rol != "AGENT" ||
        this.rol != "AGENTCC" ||
        this.rol != "MANAGERCC" ||
        this.rol != "ADMINCC"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.canCreateSolicitudes();
    console.log(localStorage);
    window.dataLayer.push({
      event: "ITFY_hub_v",
      no_cotizacion: undefined,
      userID: localStorage.agenteMail,
      correo: undefined,
      telefono: undefined,
    });
  },
};
</script>
<style scoped>
.cardConatinerSolicitud {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.cardDescriptionSolicitud {
  margin: 0;
  padding: 0;
}
.cardDescriptionSolicitud h6,
h4 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0%;
  color: rgba(0, 165, 223, 1);
}

.cardDescription {
  padding: 0;
  color: rgba(77, 83, 88, 1);
}

.cardDescription h2 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 32px;
  line-height: 35.2px;
  letter-spacing: 0%;
  color: rgba(77, 83, 88, 1);
  margin-bottom: 10px;
}

.cardDescription span {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
  letter-spacing: 0%;
  color: rgba(77, 83, 88, 1);
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 16px;
  padding: 0;
}

.banner-home {
  width: 100%;
}
.card {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}
.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.cardsHome:hover {
  border: 1px solid #133b7d;
}
.cardsHome {
  border: 1px solid transparent;
}
.cardsHome:hover .cardAccionHome {
  background: #133b7d;
}

.cursor {
  cursor: pointer;
}
</style>
