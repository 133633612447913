export const statusMapAlianza = {
  "-1": { text: "Baja", color: "red" },
  "0": { text: "Nuevo", color: "blue" },
  "1": { text: "Activo", color: "#25A249" },
  "2": { text: "Inactivo", color: "#82898E" },
  defaultStatus: { text: "N/A", color: "black" },
};

export const digitalConsultantHeaders = [
  {
    text: "ID",
    value: "id",
    width: "50px",
  },
  {
    text: "Estatus",
    value: "status",
    align: "center",
    width: "100px",
  },
  {
    text: "Nombre",
    value: "nombreCompleto",
    width: "200",
  },
  {
    text: "Celular",
    value: "celular",
    sortable: false,
    width: "120",
  },
  {
    text: "Correo Electrónico",
    value: "correo",
    sortable: false,
    width: "240",
  },
  {
    text: "RFC",
    value: "rfc",
    sortable: false,
    width: "150",
  },
  {
    text: "Fecha de Registro",
    value: "fechaRegistro",
    width: "150px",
    align: "center",
  },
  {
    text: "Ver Agente",
    value: "uuid",
    width: "100px",
    sortable: false,
    align: "center",
  },
];

export const defaultHeaders= [
        {
          text: "ID",
          value: "id",
          width: "50px",
        },
        {
          text: "Estatus",
          value: "status",
          align: "center",
          width: "100px",
        },
        {
          text: "Nombre",
          value: "nombreCompleto",
          width: "200",
        },
        {
          text: "Celular",
          value: "celular",
          sortable: false,
          width: "120",
        },
        {
          text: "Correo Electrónico",
          value: "correo",
          sortable: false,
          width: "240",
        },
        {
          text: "Fecha de Registro",
          value: "fechaRegistro",
          width: "150px",
          align: "center",
        },
        {
          text: "Ver Agente",
          value: "uuid",
          width: "100px",
          sortable: false,
          align: "center",
        },
      ];
