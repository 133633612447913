<template>
  <div>
    <v-row>
      <v-col cols="10" md="10">
        <span class="titulo-header">Cotizaciones</span>
      </v-col>
      <!-- <v-col cols="12" md="2">
        <v-btn class="common-botton" dark block >
          <v-icon start> mdi-plus-circle </v-icon>
          Agregar
        </v-btn>
      </v-col> -->
    </v-row>
    <div style="padding-top: 30px;" v-if="globalConfig.showMainHubGraphs">
        <StatsCotizaciones></StatsCotizaciones>
    </div>
    <div style="padding-top: 30px;">
        <FiltersCotizacionesIndex></FiltersCotizacionesIndex>
    </div>
  </div>
</template>


<script>

import FiltersCotizacionesIndex from './FiltersCotizacionesIndex.vue';
import StatsCotizaciones from './StatsCotizaciones.vue';

export default {
  components: {
    FiltersCotizacionesIndex,
    StatsCotizaciones
  },

  data() {
    return {
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")),
    };
  },
  methods: {
    checkAlianzaId() {
      const path = this.$route.path;
      if (path.includes('alianza/perfil/')) {
      this.globalConfig.showMainHubGraphs = false;
      }
    }
  },
  mounted() {
    this.checkAlianzaId();
  }
};
</script>