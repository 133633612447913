export const statusMap = {
  "1": { text: "Nuevo", color: "green" },
  "2": { text: "Prospección", color: "green" },
  "3": { text: "Convertido", color: "green" },
  "4": { text: "Rechazado", color: "green" },
  "5": { text: "Declinado", color: "green" },
  defaultStatus: { text: "N/A", color: "black" },
};

export const headers = [
  {
    text: "ID",
    value: "id",
    width: "50px",
  },
  {
    text: "Estatus",
    value: "status",
    align: "center",
    width: "90px",
  },
  {
    text: "Nombre",
    value: "nombreCompleto",
    width: "170",
  },
  {
    text: "Celular",
    value: "celular",
    sortable: false,
    width: "100",
  },
  {
    text: "Correo Electrónico",
    value: "correo",
    sortable: false,
    width: "240",
  },
  {
    text: "RFC",
    value: "rfc",
    sortable: false,
    width: "150",
  },
  {
    text: "Fecha de Registro",
    value: "fechaRegistro",
    width: "150px",
    align: "center",
  },
  {
    text: "Ver Agente",
    value: "uuid",
    width: "100px",
    sortable: false,
    align: "center",
  },
];
