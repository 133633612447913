<template >
  <v-row
    class="d-flex align-center justify-center"
    style="background-color: #001b30; min-height: 100vh"
    no-gutters
  >
    <v-col cols="12" class="d-flex justify-center">
      <v-card style="border-radius: 20px; width: 85%; height: 100%">
        <v-row>
          <v-col cols="12" sm="4" class="d-flex align-center pr-0" >
            <v-img
              v-show="$vuetify.breakpoint.mdAndUp"
              :src="GirlLaptop"
              cover height="100%"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="8" class="main-container-div">
            <div v-if="!cargando">
              <DatosPersonalesProspectos
                @isNextStep="isNextStep"
                v-if="step === 1"
                :createAccount="createAccount"
                :contactInformation="contactInformation"
                :emailsRepeat="emailsRepeat"
                :phoneRepeat="phoneRepeat"
                :telRepeat="telRepeat"
              />
              <!-- <OtpProspectos v-if="step === 2" /> -->
              <CrearContraseniaProspecto
                v-if="step === 2"
                :password="password"
                @insert="insert"
              />
              <SuccessPage v-if="step === 3" @login="login" />
            </div>
            <div v-else>
              <v-row
                style="
                  width: 100%;
                  padding: 16px;
                  border-radius: 20px;
                  min-height: 500px;
                "
                class="d-flex align-md-center justify-md-center"
              >
                <v-card-text class="text-center">
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex align-md-center justify-md-center"
                    >
                      <v-img
                        class="responsive"
                        contain
                        max-height="150"
                        max-width="300"
                        :src="loginTemplate.createAccount"
                      ></v-img>
                    </v-col>
                    <v-col cols="12">
                      <h1>Iniciando sesión...</h1>
                      <span>Espere un momento por favor</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <CommonTenentConfigModal
      :dialog="configModal"
      @closeModal="configModal = false"
    />
    <PixelScriptsRegistro />
  </v-row>
</template>

<script>
import GirlLaptop from "@/assets/chicaLaptopMano.png";
import LogoInter from "@/assets/interify.svg";
import DatosPersonalesProspectos from "./DatosPersonalesProspectos.vue";
// import OtpProspectos from "./OtpProspectos.vue";
import { mainAxios } from "../../../mainAxios.js";
import { axiosLogin } from "../../../axiosLogin.js";
import CrearContraseniaProspecto from "./CrearContraseniaProspecto.vue";
import loginData from "../../../helpers/data/data-login-template.json";
import SuccessPage from "./SuccessPage.vue";
import CommonTenentConfigModal from "../../../components/commonComponents/dialogs/CommonTenentConfigModal.vue";
import PixelScriptsRegistro from "../../../components/pixeles/PixelScriptsRegistro.vue";
import { tenentConfig } from "@/helpers/tenent-config";
export default {
  name: "CreacionProspecto",
  components: {
    DatosPersonalesProspectos,
    CrearContraseniaProspecto,
    SuccessPage,
    CommonTenentConfigModal,
    PixelScriptsRegistro
    // OtpProspectos,
  },
  data() {
    return {
      cargando: false,
      loginTemplate: loginData,
      GirlLaptop: GirlLaptop,
      LogoInter: LogoInter,
      step: 1,
      prevStep: 0,
      configModal: false,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      createAccount: {
        name: "",
        surname: "",
        lastname: "",
        birthday: "",
        placeBirth: 0,
        sex: "",
        isRfc: true,
        generatedRfc: "",
        homoclave: "",
      },
      contactInformation: {
        phone: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        correo: "",
        tel: "",
      },
      password: "",
      emailsRepeat: [],
      phoneRepeat: "",
      telRepeat: "",
    };
  },
  methods: {
    isNextStep(isNext, value) {
      this.prevStep = this.step;
      if (isNext) {
        this.step++;
      } else if (value <= this.step) {
        this.step = value;
      }
    },

    insert(password) {
      const dataPetition = {
        apellidoMaterno: this.createAccount.lastname,
        apellidoPaterno: this.createAccount.surname,
        celular: this.contactInformation.tel,
        correo: this.contactInformation.correo,
        correos: this.contactInformation.emails,
        fechaNacimiento: this.createAccount.birthday,
        nacimientoEstadoId: this.createAccount.placeBirth,
        nombre: this.createAccount.name,
        password: password,
        rfc: `${this.createAccount.generatedRfc}${this.createAccount.homoclave}`,
        sexo: this.createAccount.sex,
        // telefono: this.contactInformation.phone,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + "7af112f1-963e-4c4b-bca9-50e45fccd00a",
        },
      };
      this.cargando = true;
      mainAxios
        .post("/v1/prospecto/crear_cuenta", dataPetition, config)
        .then(({ data: { data, message, status } }) => {
          if (status == 200 && message === "Datos del prospecto creado") {
            this.password = password;
            this.cargando = false;
            this.step = 3;
            // this.login();
          } else {
            if (data.error === "emailsExits") {
              this.cargando = false;
              this.emailsRepeat = data.correos;
              this.phoneRepeat = data.celular;
              this.telRepeat = data.telefono;
              this.step = 1;
            } else {
              console.log("error");
              this.cargando = false;
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.cargando = false;
            if (error.response.data.message) {
              this.step = 1;
              this.$toast.warning(error.response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          }
        });
    },
    login() {
      var qs = require("qs");
      var vm = this;
      vm.error = false;
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: "admin",
          password: "g6PqgxyB0Z",
        },
      };
      var data = qs.stringify({
        grant_type: "password",
        username: this.contactInformation.correo,
        password: this.password,
      });
      axiosLogin
        .post("/oauth/token", data, config)
        .then((value) => {
          localStorage.agenteAccessToken = value.data.access_token;
          const configA = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          this.getUser(configA);
        })
        .catch((err) => {
          console.log(err);
          vm.error = true;
          vm.cargando = false;
          this.error = true;
        });
    },
    getUser(configA) {
      axiosLogin.get("/user", configA).then((value) => {
        localStorage.id = value.data.principal.id;
        localStorage.agenteName =
          value.data.principal.nombre +
          " " +
          value.data.principal.apellidoPaterno;
        localStorage.agenteUuid = value.data.principal.uuid;
        localStorage.agenteMail = this.contactInformation.correo;
        if (value.data.authorities[3].authority == "PROSPECTO") {
          this.roles = [
            {
              id: null,
              uuid: null,
              rol: "PROSPECTO",
              nombre: "Prospecto",
              canal: null,
              informa_a: "ADMINCC",
              nivel: null,
              notas: null,
              agente_id: null,
            },
          ];
          this.changeView();
        } else {
          this.getRoles(configA);
        }
      });
    },
    getRoles(configA) {
      mainAxios.get("/v1/agente/roles", configA).then((value) => {
        this.roles = value.data;
        this.changeView();
      });
    },
    changeView() {
      var vm = this;
      localStorage.setItem("roles", JSON.stringify(this.roles));
      if (Object.keys(this.$route.query).length === 0) {
        switch (this.roles[0].rol) {
          case "ADMINCC":
          case "AGENTCCSPONSORS":
            vm.$router.push({ path: "/leads" });
            break;
          case "PROSPECTO":
            vm.$router.push({ path: "/inicio-prospectos" });
            break;
          default:
            vm.$router.push({ path: "/inicioVista" });
            break;
        }
      } else {
        vm.$router.push({ path: `${this.$route.query.from}` });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("globalConfig") == null) {
      var appliedConfig = tenentConfig();
      if (appliedConfig.isDevelop) {
        this.configModal = true;
      } else if (appliedConfig.applied) {
        this.globalConfig = JSON.parse(localStorage.getItem("globalConfig"));
        location.reload();
      }
    }
  },
  computed: {
    isDev() {
      if (window.location.hostname == "localhost") return true;
      else return false;
    },
  },
};
</script>

<style scoped>
/* @media (min-width: 1800px) {
  .container-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 690px;
  }
} */
.main-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
</style>
