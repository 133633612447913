var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Solicitud")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cliente ")])])],1),(_vm.tabReplace == 1)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('SolicitudLlamadasIndex',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('RequiredSolicitudesInfo',{attrs:{"datosSolicitud":_vm.datosSolicitud,"operadores":_vm.operadores},on:{"getData":_vm.updateView}})],1)]):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('ClienteSolcitudInfo',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('ContactClienteSolcitudInfo',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('DomicilioSolcitudInfo',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',[(
            _vm.rol == 'MESACONTROLINBOUND' ||
              _vm.rol == 'OPERADORINBOUND' ||
              _vm.rol == 'OPERACIONESINBOUND' ||
              _vm.rol == 'ADMIN'
          )?_c('EmbeddedCliente',{attrs:{"telefonos":_vm.telefonosCliente,"cliente_id":_vm.datosSolicitud.solicitudBody.cliente_id,"is_solicitud":true}}):_vm._e()],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }