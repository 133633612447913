<template>
  <common-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row style="padding: 15px 20px 0px 20px;">
            <v-col cols="12" md="6">
              <h1>Lista de agentes asignados</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <tabla-alianza
              :loading="loading"
              :items="agentesAsignados"
              :editItem="editItem"
              :statusMap="statusMap"
              :headers="headers"
            />
        </v-col>
      </v-row>
    </v-container>
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import moment from "moment";
import TablaAlianza from '~/views/pages/administrador/alianzas/perfil/components/TablaAlianza.vue';
import {statusMap, headers} from './utils/constants.js';
import {mainAxios} from '~/mainAxios.js';

export default {
  name: "AgentesAsignados",
  components: {
    CommonCard,
    TablaAlianza,
  },
  data() {
    return {
      statusMap: statusMap,
      headers: headers,
      agentesAsignados: [],
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
      },
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YY HH:mm");
    },
    verAgente(item) {
      this.$router.push({
        name: "PerfilAgenteAsignado",
        params: { agente_id: item.id },
      });
    },
    async getAgent() {
      await mainAxios.get(`v1/agente/asignacion/info/${localStorage.id}`, this.config).then((res) => {
        this.agentesAsignados = res.data;
      });
    },
  },
  mounted(){
    this.getAgent()
  }
};
</script>

<style scoped></style>
