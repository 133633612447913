<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Estadísticas
    </template>
    <v-expansion-panel-content>
      <div class="stats-container">
        <!-- Fila para el filtro de fechas -->
        <v-row class="mb-4">
          <v-col cols="12" md="7">
            <div class="d-md-flex align-center">
              <span class="text-subtitle-1 font-weight-bold mr-2 filter-title">
                Filtrar por periodo
              </span>
              <InputPeriodo
                @updateDate="getTodasLasSolicitudes"
                class="filter-input"
              />
            </div>
          </v-col>
        </v-row>

        <div v-if="!loading">
          <v-row v-if="!noResults">
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col
                  v-for="(item, index) in filteredSolicitudes"
                  :key="index"
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <div class="coverage-card">
                    <div class="coverage-text">
                      <span class="coverage-label">{{ item.name }}</span>
                      <span class="coverage-count">{{ item.value }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Gráfico de solicitudes -->
            <v-col cols="12" md="6" class="chart-col">
              <SolicitudesStatusGraphic :solicitudes="solicitudes" />
            </v-col>
          </v-row>

          <!-- Sin resultados -->
          <div v-else>
            <v-col cols="12" md="12" class="text-center py-5">
              <span class="titulo-header">Sin resultados que mostrar</span>
            </v-col>
          </div>
        </div>
        <!-- Indicador de carga -->
        <div v-else>
          <v-row>
            <v-col cols="12" class="d-flex align-center justify-center">
              <!-- Aquí podrías colocar un spinner o algo similar -->
            </v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import SolicitudesStatusGraphic from "@/components/commonComponents/charts/SolicitudesStatus.graphic.vue";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    SolicitudesStatusGraphic,
    CommonExpansionPanel,
    InputPeriodo,
  },
  data() {
    return {
      solicitudes: {},
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      noResults: false,
      loading: false,
    };
  },
  computed: {
    filteredSolicitudes() {
      if (!this.solicitudes.status) return [];
      return Object.entries(this.solicitudes.status)
        .filter(([, value]) => value > 0)
        .map(([name, value]) => ({ name, value }));
    },
  },
  methods: {
    getTodasLasSolicitudes(dates) {
      this.loading = true;
      let body = { fechas: dates };
      if (String(window.location.pathname).includes("/alianza/perfil/")) {
        body.alianza = parseInt(this.$route.params.alianza_id);
      }
      mainAxios
        .post("/v1/solicitud/info/status/1", body, this.requestConfig)
        .then(({ data }) => {
          this.solicitudes = data;
          this.loading = false;
          this.checkIfHasResults();
        })
        .catch(() => {
          this.loading = false;
          this.solicitudes = {};
          this.checkIfHasResults();
        });
    },
    checkIfHasResults() {
      this.noResults = this.filteredSolicitudes.length === 0;
    },
  },
};
</script>

<style scoped>
.stats-container {
  /* Ajusta según necesites. */
  padding: 30px;
}

.filter-title {
  color: #4D5358;
  font-size: 1rem;
  margin-top: -31px; /* Si deseas que aparezca más arriba */
}

.filter-input {
  min-width: 50%;
}

.coverage-card {
  display: flex;
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 12px;
  padding: 12px; /* Ajustado para que no ocupe demasiado espacio */
  margin: 4px;   /* Ajustado para reducir espacios en pantalla */
  cursor: default;
}

.coverage-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coverage-label,
.coverage-count {
  color: #2D2F33;
  font-weight: bold;
  font-size: 0.9rem; /* Más pequeño que 1rem */
}

/* Permite que el contenedor de la gráfica se adapte sin forzar scroll horizontal */
.chart-col {
  overflow: visible;
}

/* Ejemplo para ocultar la gráfica en pantallas < 600px (móvil) */
@media (max-width: 600px) {
  .chart-col {
    display: none !important;
  }
}
</style>
